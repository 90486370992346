import {FC, lazy, Suspense, useEffect} from 'react';
import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {MenuTestPage} from '../pages/MenuTestPage';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../_metronic/helpers';
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper';
import State from '../pages/BussinesSettings/state';
import Division from '../pages/BussinesSettings/division';
import Area from '../pages/BussinesSettings/area';
import Role from '../pages/roleManagement/roles';
import Users from '../pages/roleManagement/users';
import ConstructionPage from '../modules/errors/underConstruction';
import Omc from '../pages/OMC/omc';
import StateOffice from '../pages/OMC/stateOffice';
import DivisionOffice from '../pages/OMC/divisionOffice';
import AreaOffice from '../pages/OMC/areaOffice';
import FuelType from '../pages/BussinesSettings/fuelType';
import VehicleType from '../pages/BussinesSettings/vehicleType';
import ManageRO from '../pages/RO/roManagement';
import Campaign from '../pages/campaign/manageCampaign';
import Campaigns from '../pages/campaign/campaignPoster';
import RegistrationList from '../pages/campaign/campaignRegList';
import CampaignRoList from '../pages/campaign/campaignRoList';
import Winner from '../pages/campaign/winnerPage';
import CampaignReport from '../pages/Report/reports';
import Prizes from '../pages/BussinesSettings/prize';
import PDFViewer from '../pages/campaign/pdfViewer';
import RegistrationListReport from '../pages/Report/regDetails';
import CampaignForm from '../pages/campaign/campaignForm';
import CustomPrizePage from '../pages/campaign/custoPrizePage';
import { useAuth } from '../modules/auth';
import WinnersList from '../pages/campaign/winnersList';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  const navigate = useNavigate();
  const {currentUser} = useAuth(); 

  useEffect(() => {
    // Add event listener for 'keydown' event
    window.addEventListener('keydown', handleEscKey);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  const handleEscKey = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      navigate(-1); // Example using react-router-dom
    } else if (event.altKey && event.key === 'a') {
      navigate('/campaign/form');
    }
  };

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='state'
          element={
            <SuspensedView>
              <State />
            </SuspensedView>
          }
        />
        <Route
          path='division'
          element={
            <SuspensedView>
              <Division />
            </SuspensedView>
          }
        />
        <Route
          path='area'
          element={
            <SuspensedView>
              <Area />
            </SuspensedView>
          }
        />
        <Route
          path='roles'
          element={
            <SuspensedView>
              <Role />
            </SuspensedView>
          }
        />
        <Route
          path='users'
          element={
            <SuspensedView>
              <Users />
            </SuspensedView>
          }
        />
        <Route
          path='construction'
          element={
            <SuspensedView>
              <ConstructionPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='omc'
          element={
            <SuspensedView>
              <Omc />
            </SuspensedView>
          }
        />
        <Route
          path='stateOffice'
          element={
            <SuspensedView>
              <StateOffice />
            </SuspensedView>
          }
        />
        <Route
          path='divisionOffice'
          element={
            <SuspensedView>
              <DivisionOffice />
            </SuspensedView>
          }
        />
        <Route
          path='officeArea'
          element={
            <SuspensedView>
              <AreaOffice />
            </SuspensedView>
          }
        />
        <Route
          path='fuelType'
          element={
            <SuspensedView>
              <FuelType />
            </SuspensedView>
          }
        />
        <Route
          path='vehicleType'
          element={
            <SuspensedView>
              <VehicleType />
            </SuspensedView>
          }
        />
        <Route
          path='manageRO'
          element={
            <SuspensedView>
              <ManageRO />
            </SuspensedView>
          }
        />
        <Route
          path='campaign'
          element={
            <SuspensedView>
              <Campaign />
            </SuspensedView>
          }
        />
        <Route
          path='campaign/form'
          element={
            <SuspensedView>
              <CampaignForm />
            </SuspensedView>
          }
        />
        {/* <Route
          path='campaign/campaignsPoster'
          element={
            <SuspensedView>
              <Campaigns />
            </SuspensedView>
          }
        /> */}
        <Route
          path='campaign/registrationList'
          element={
            <SuspensedView>
              <RegistrationList />
            </SuspensedView>
          }
        />
        <Route
          path='campaign/campaignRoList'
          element={
            <SuspensedView>
              <CampaignRoList />
            </SuspensedView>
          }
        />
        <Route
          path='campaign/customPrice'
          element={
            <SuspensedView>
              <CustomPrizePage />
            </SuspensedView>
          }
        />
        <Route
          path='campaign/customPrice/winnersList'
          element={
            <SuspensedView>
              <WinnersList />
            </SuspensedView>
          }
        />
        <Route
          path='prizePage'
          element={
            <SuspensedView>
              <Prizes />
            </SuspensedView>
          }
        />
        <Route
          path='reports'
          element={
            <SuspensedView>
              <CampaignReport />
            </SuspensedView>
          }
        />
        <Route
          path='regReport'
          element={
            <SuspensedView>
              <RegistrationListReport />
            </SuspensedView>
          }
        />
        <Route
          path='role'
          element={
            <SuspensedView>
              <Role />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
