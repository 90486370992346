import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

export const STATE_LIST_URL = `${API_URL}/state/list`
export const STATE_SAVE_URL = `${API_URL}/state/save`
export const STATE_UPDATE_URL = `${API_URL}/state/update`
export const STATE_DELETE_URL = `${API_URL}/state/delete`
export const DIVISIONS_LIST_URL = `${API_URL}/division/list`
export const DIVISIONS_SAVE_URL = `${API_URL}/division/save`
export const DIVISIONS_UPDATE_URL = `${API_URL}/division/update`
export const DIVISIONS_DELETE_URL = `${API_URL}/division/delete`
export const AREA_LIST_URL = `${API_URL}/area/list`
export const AREA_SAVE_URL = `${API_URL}/area/save`
export const AREA_UPDATE_URL = `${API_URL}/area/update`
export const AREA_DELETE_URL = `${API_URL}/area/delete`
export const MASTER_LIST_URL = `${API_URL}/masters_list`
export const MASTER_SAVE_URL = `${API_URL}/masters_save`
export const MASTER_UPDATE_URL = `${API_URL}/masters_update`
export const MASTER_DELETE_URL = `${API_URL}/masters_delete`

export const PRIZE_LIST_URL = `${API_URL}/prize_master_list`
export const PRIZE_SAVE_URL = `${API_URL}/prize_master_save`
export const PRIZE_UPDATE_URL = `${API_URL}/prize_master_update`
export const PRIZE_DELETE_URL = `${API_URL}/prize_master_delete`

//state
export function getStates() {
    return axios.get(STATE_LIST_URL)
    .then(res => res.data)
}

export function saveStates(data) {
    return axios.post(STATE_SAVE_URL, data)
    .then(res => res.data)
}

export function updateStates(id, data) {
    return axios.put(STATE_UPDATE_URL+'/'+id, data)
    .then(res => res.data)
}

export function deleteStates(id) {
    return axios.put(STATE_DELETE_URL+'/'+id)
    .then(res => res.data)
}

//division
export function getDivisions() {
    return axios.get(DIVISIONS_LIST_URL)
    .then(res => res.data)
}

export function saveDivisions(data) {
    return axios.post(DIVISIONS_SAVE_URL, data)
    .then(res => res.data)
}

export function updateDivisions(id, data) {
    return axios.put(DIVISIONS_UPDATE_URL+'/'+id, data)
    .then(res => res.data)
}

export function deleteDivisions(id) {
    return axios.put(DIVISIONS_DELETE_URL+'/'+id)
    .then(res => res.data)
}

//area
export function getAreas() {
    return axios.get(AREA_LIST_URL)
    .then(res => res.data)
}

export function saveAreas(data) {
    return axios.post(AREA_SAVE_URL, data)
    .then(res => res.data)
}

export function updateAreas(id, data) {
    return axios.put(AREA_UPDATE_URL+'/'+id, data)
    .then(res => res.data)
}

export function deleteAreas(id) {
    return axios.put(AREA_DELETE_URL+'/'+id)
    .then(res => res.data)
}

//area
export function getMaster(opt) {
    return axios.get(MASTER_LIST_URL+'?option_type='+opt)
    .then(res => res.data)
}

export function saveMaster(data) {
    return axios.post(MASTER_SAVE_URL, data)
    .then(res => res.data)
}

export function updateMaster(id, data) {
    return axios.put(MASTER_UPDATE_URL+'/'+data.option_type+'/'+id, data)
    .then(res => res.data)
}

export function deleteMaster(id) {
    return axios.put(MASTER_DELETE_URL+'/'+id)
    .then(res => res.data)
}

//prize
export function getPrize() {
    return axios.get(PRIZE_LIST_URL)
    .then(res => res.data)
}

export function savePrize(data) {
    return axios.post(PRIZE_SAVE_URL, data)
    .then(res => res.data)
}

export function updatePrize(id, data) {
    return axios.put(PRIZE_UPDATE_URL+'/'+id, data)
    .then(res => res.data)
}

export function deletePrize(id) {
    return axios.put(PRIZE_DELETE_URL+'/'+id)
    .then(res => res.data)
}