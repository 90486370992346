import react, {useState, useEffect} from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { deleteRo, getRo, importRo, saveRo, updateRo } from './core/_requests';
import { toast } from 'react-toastify';
import { Paginator } from 'primereact/paginator';
import { PageTitle } from '../../../_metronic/layout/core/PageData';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getAreaOffice, getDivisionOffice, getOmc, getStateOffice } from '../OMC/core/_request';
import { getAreas, getDivisions, getStates } from '../BussinesSettings/core/_requests';

const ManageRO = () => {
    const [ro, setRo] = useState<any[]>([]);
    const [omc, setOmc] = useState<any[]>([]);
    const [divisions, setDivisions] = useState<any[]>([]);
    const [areas, setAreas] = useState<any[]>([]);
    const [pageData, setPageData] = useState<any[]>([]);
    const [states, setStates] = useState<any[]>([]);
    const [RoForSearch, setRoForSearch] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>({});
    const [editId, setEditId] = useState<any>({});
    const [search, setSearch] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [pdfFile, setImageFile] = useState<any>(null);
    const [ros, setRos] = useState([{
        "ro_id": '',
        "ro_name": '',
        "sap_code": '',
        "status": '1'
    }]);
    // const [omcDetail, setOmcDetail] = useState<any>({});
    // const queryParams = new URLSearchParams(window.location.search);
    // const omc = queryParams.get('omc_id');

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const OmcList = async () => {
        const response = await getOmc();
        setOmc(response.output);
    }

    const areaOfficeList = async () => {
        const response = await getAreaOffice();
        setAreas(response.output);
    }

    const stateOfficeList = async () => {
        const response = await getStateOffice();
        setStates(response.output);
    }

    const divisionOfficeList = async () => {
        const response = await getDivisionOffice();
        setDivisions(response.output);
    }

    const RoList = async () => {
        setLoading(true);
        const response = await getRo();
        setRo(response.output);
        setRoForSearch(response.output);
        setLoading(false);
    }

    const initialValues = {
        "omc_id": '',
        "state": '',
        "division": '',
        "area": '',
        "sap_code": '',
        "type": '',
        "ro_name": ''
    }

    const roSchema = Yup.object().shape({ 
        state: Yup.string()
            .required('state is required'),
        division: Yup.string()
            .required('division is required'), 
        area: Yup.string()
            .required('area is required'),
        omc_id: Yup.string()
            .required('OMC is required'),
        type: Yup.string()
            .required('RO Type is required'),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: roSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
            // let body = {
            //     "omc_id": values.omc_id,
            //     "state": values.state,
            //     "division": values.division,
            //     "area": values.area,
            //     "type": values.type,
            //     "sap_code": '',
            //     "ro_name": JSON.stringify(ros),
            // }

            const formData = new FormData();
            pdfFile && formData.append('import', pdfFile!);
            formData.append('omc_id', values.omc_id);
            formData.append('state', values.state);
            formData.append('division', values.division);
            formData.append('area', values.area);
            formData.append('type', values.type);
            formData.append('sap_code', values.sap_code);
            pdfFile == null && formData.append('ro_name', JSON.stringify(ros));

            let response;
            if(pdfFile == null) {
            if(editId && editId.id != undefined) {
                response = await updateRo(editId.id, formData)
            } else {
                response = await saveRo(formData)
            }} else {
                response = await importRo(formData)
            }  

            if(response.status == 200) {
                setLoading(false);
                RoList();
                resetForm();
                (document.getElementById('wejhrguwegrkwgrhweg') as HTMLInputElement).value = '';
                document.getElementById('atom_Ro_add_modal_close')?.click();
                toast.success(response.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
          } catch (error) {
            console.error(error)
            toast.error("Somethig went wrong, Please try after sometimes!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setStatus('Server Error')
            setSubmitting(false)
            setLoading(false)
          }
        },
    })

    const handleDelete = async () => {
        let body = {
            "state": deleteId.state,
            "division": deleteId.division,
            "area": deleteId.area,
            "omc_id": deleteId.omc_id
        }
        const response = await deleteRo(body)
        if(response.status == 200) {
            setDeleteId('');
            document.getElementById('atom_Ro_delete_modal_close')?.click();
            RoList();
            toast.success(response.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(response.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const isValidFileUploaded=(file:any)=>{
        console.log("rhweuorietiugwudfgrry", file);
        
        const validExtensions = ['csv','vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'vnd.ms-excel']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
    }

    const handleImportRo = async (e) => {
        if(e.target.files[0].size > 10485760){
            toast.error("File size should be below 10MB !", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            (document.getElementById('wejhrguwegrkwgrhweg') as HTMLInputElement).value = '';
            return;
        } else {
            const file = e.target.files[0];
            if(isValidFileUploaded(file)){
                let image_as_files:any = e.target.files[0];            
                setImageFile(image_as_files);
                // const formData = new FormData();
                // formData.append('filename', image_as_files);

                // const response = await importRo(formData);
                // if(response.status == 200) {
                //     toast.success("RO imported successfully!", {
                //         position: "bottom-center",
                //         autoClose: 5000,
                //         hideProgressBar: false,
                //         closeOnClick: true,
                //         pauseOnHover: true,
                //         draggable: true,
                //         progress: undefined,
                //         theme: "light",
                //     });
                // }
           } else { 
            (document.getElementById('wejhrguwegrkwgrhweg') as HTMLInputElement).value = '';
            toast.error("File should be only csv or xlsx!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
           }  
        }
    }
    

    useEffect(() => {
        if(editId && editId.id != undefined) {
            document.getElementById('ronebrkjgkjhbckrjygtuyg34lh')?.click();
            formik.setFieldValue('omc_id', editId.omc_id);
            formik.setFieldValue('state', editId.state);
            formik.setFieldValue('division', editId.division);
            formik.setFieldValue('area', editId.area);
            // formik.setFieldValue('sap_code', editId.sap_code);
            // formik.setFieldValue('ro_name', editId.ro_name);
            formik.setFieldValue('type', editId.type);
            // setRos(editId.ro_name?.split(','))
            let jhqgeuyq: any = [];
            JSON.parse(editId.json_data)?.map((_item: any) => {
                let mwehgj = {
                    "ro_id": _item.ro_id,
                    "ro_name": _item.ro_name,
                    "sap_code": _item.sap_code,
                    "status": _item.status
                };
                jhqgeuyq.push(mwehgj);
            })
            console.log("rektherothieourtieur", jhqgeuyq);   
            setRos(jhqgeuyq);          
        }
    }, [editId])

    useEffect(() => {
        if(search.length > 0) {
            const ro = RoForSearch.filter((p) => p.omc_name?.toLowerCase().includes(search) || p.omc_shortcode?.toLowerCase().includes(search) || p.ro_name?.toLowerCase().includes(search) || p.state_name?.toLowerCase().includes(search) || p.division_name?.toLowerCase().includes(search) || p.area_name?.toLowerCase().includes(search));
            setRo(ro);
        } else {
            setRo(RoForSearch);
        }
    }, [search]);

    useEffect(() => {
        RoList();
        areaOfficeList();
        stateOfficeList();
        divisionOfficeList();
        OmcList();
    }, []);
    
    useEffect(() => {        
        let page = ro?.slice(first, first+rows);
        setPageData(page);
    }, [first, rows, ro]);

  return (<>
  <PageTitle>Manage RO</PageTitle>
  <button type="button" className="d-none" data-bs-toggle='modal' data-bs-target='#atom_Ro_add_modal' id='ronebrkjgkjhbckrjygtuyg34lh'>open</button>
    <div className='modal fade' id='atom_Ro_add_modal' aria-hidden='true' data-bs-keyboard="false" data-bs-backdrop="static">
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>Add RO</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_Ro_add_modal_close' onClick={() => {
                formik.resetForm();
                setDeleteId('');
                setSearch('');
                setEditId({});
                setRos([{
                    "ro_id": '',
                    "ro_name": '',
                    "sap_code": '',
                    "status": '1'
                }]);
            }}>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10 pt-5 mh-650px'>
            <form noValidate onSubmit={formik.handleSubmit}>
                <div className="row"> 
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">OMC</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select {...formik.getFieldProps('omc_id')} className="form-control" onChange={(e) => {
                                formik.setFieldValue('state', '');
                                formik.setFieldValue('division', '');
                                formik.setFieldValue('area', '');
                                formik.setFieldValue('omc_id', e.target.value);
                            }} >
                                <option value="" >select OMC</option>
                                {omc.map((omc, i) => {
                                    return(
                                        <option value={omc.id} key={i} >{omc.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {formik.touched.omc_id && formik.errors.omc_id && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.omc_id}</span>
                                </div>
                            </div>
                        )}
                    </div>   
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">State Office</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select {...formik.getFieldProps('state')} className="form-control" onChange={(e) => {
                                formik.setFieldValue('division', '');
                                formik.setFieldValue('area', '');
                                formik.setFieldValue('state', e.target.value);
                            }} >
                                <option value="" >select State Office</option>
                                {states.map((omc, i) => {
                                    if(formik.values.omc_id == omc.omc_id) {
                                    return(
                                        <option value={omc.id} key={i} >{omc.state}</option>
                                    )}
                                })}
                            </select>
                        </div>
                        {formik.touched.state && formik.errors.state && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.state}</span>
                                </div>
                            </div>
                        )}
                    </div>   
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">Retail Office</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select {...formik.getFieldProps('division')} className="form-control" onChange={(e) => {
                                formik.setFieldValue('area', '');
                                formik.setFieldValue('division', e.target.value);
                            }} >
                                <option value="" >select Retail Office</option>
                                {divisions.map((div, i) => {
                                    if(formik.values.state == div.state) {
                                    return(
                                        <option value={div.id} key={i} >{div.division}</option>
                                    )}
                                })}
                            </select>
                        </div>
                        {formik.touched.division && formik.errors.division && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.division}</span>
                                </div>
                            </div>
                        )}
                    </div>   
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">Area Office</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select {...formik.getFieldProps('area')} className="form-control" >
                                <option value="" >select Area Office</option>
                                {areas.map((are, i) => {
                                    if(formik.values.division == are.division) {
                                    return(
                                        <option value={are.id} key={i} >{are.city}</option>
                                    )}
                                })}
                            </select>
                        </div>
                        {formik.touched.area && formik.errors.area && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.area}</span>
                                </div>
                            </div>
                        )}
                    </div>  
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">RO Type</label>
                        <div className="input-group first mb-3 input_prepend">
                            <input type="text" className="form-control" placeholder="Enter RO Type.." {...formik.getFieldProps('type')} />
                        </div>
                        {formik.touched.type && formik.errors.type && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.type}</span>
                                </div>
                            </div>
                        )}
                    </div>  
                    <div className='col-12 ro_name_bg p-7 mb-4'> 
                    <div className='text-end'>
                        <label htmlFor='wejhrguwegrkwgrhweg' className=''>
                            <input
                                type="file" onChange={(e) => handleImportRo(e)}
                                id="wejhrguwegrkwgrhweg" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                className="form-control d-none"
                            />
                            <span className='btn btn-sm btn-primary'><i className="bi bi-cloud-upload"></i> import</span>
                        </label>
                    </div>
                    <p className='text-end'><small>{pdfFile?.name}</small></p>
                    {pdfFile == null && <>
                    {ros.map((item:any, i) => {
                        if(item.status == 1) {
                        return(
                        <div className='' key={i}>                  
                            <div className='w-100 d-flex'>
                                <div className="px-2 w-50">
                                    <label htmlFor="basic-url" className="form-label">RO Code</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" placeholder="Enter RO Code.." value={item.sap_code} onChange={(e) => {
                                            setRos(prevData => {
                                                const updatedData = [...prevData];
                                                const objectToUpdate = updatedData[i];
                                                
                                                if (objectToUpdate) {
                                                    objectToUpdate.sap_code = e.target.value;
                                                }
                                                
                                                return updatedData;
                                                });
                                        }} />
                                    </div>
                                </div>
                                <div className="px-2 w-50">
                                    <label htmlFor="basic-url" className="form-label">RO Name</label>
                                    <div className="input-group first mb-3 input_prepend">
                                        <input type="text" className="form-control" placeholder="Enter RO Name.." value={item.ro_name} onChange={(e) => {
                                            setRos(prevData => {
                                                const updatedData = [...prevData];
                                                const objectToUpdate = updatedData[i];
                                                
                                                if (objectToUpdate) {
                                                    objectToUpdate.ro_name = e.target.value;
                                                }
                                                
                                                return updatedData;
                                                });
                                        }} />
                                    </div>
                                </div>   
                                <span className='d-flex align-items-center'>
                                    <button type='button' className='btn btn-sm btn-secondary btn-active-color-danger btn-icon px-2 mt-3' onClick={() => {
                                        setRos(prevData => {
                                            const updatedData = [...prevData];
                                            const objectToUpdate = updatedData[i];
                                            
                                            if (objectToUpdate) {
                                                objectToUpdate.status = '0';
                                            }
                                            
                                            return updatedData;
                                        });
                                    }}>                                
                                        <i className="bi bi-dash-lg"></i>
                                    </button>
                                </span>
                            </div>                            
                        </div>
                        )}
                    })}
                    <div className={'d-flex justify-content-end'}>
                    <button type='button' className='btn btn-sm btn-secondary btn-active-color-danger btn-icon px-2 mt-5' onClick={() => {
                        setRos([...ros, {
                            "ro_id": '',
                            "ro_name": '',
                            "sap_code": '',
                            "status": '1'
                        }]);
                    }}>
                        <i className="bi bi-plus-lg"></i>
                    </button>
                    </div></>}                     
                    </div>
                </div>
                <span className='d-flex justify-content-end'>
                    <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal' onClick={() => {
                        formik.resetForm();
                        setDeleteId('');
                        setSearch('');
                        setEditId({});
                    }}>Discard</button>
                    <button type='submit' className='btn btn-sm btn-primary'>Save</button>
                </span>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className='modal fade' id='atom_Ro_delete_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>Confirmation</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_Ro_delete_modal_close'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10'>
            <p>Are you sure want to delete?</p>
            <span className='d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>No</button>
                <button type='submit' className='btn btn-sm btn-primary' onClick={() => handleDelete()}>Yes</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    {loading ?
    <div className='w-100 h-100'>
        <div className='d-flex justify-content-center flex-column align-items-center h-100'>
            <div className="spinner-border taskloader" role="status">                                    
                <span className="sr-only">Loading...</span>
            </div>
        </div> 
    </div> :
    <div className='card'>
        <div className='card-header d-flex align-items-center mobile-padding'>
            {/* <div className='d-flex align-items-center'>
                <div className='prof_img_pre_list border'>
                {omcDetail.logo ? 
                    <img src={process.env.REACT_APP_API_URL+'/uploads/company/logo/'+omcDetail.id+'/'+omcDetail.logo} className='profile_image w-100'/> : 
                    <p className='h-100 d-flex justify-content-center align-items-center bg-gray-300'>LOGO</p>}
                </div>
                <div className='d-flex flex-column ms-3'>
                    <h3 className='mb-1'>{omcDetail.name}</h3>
                    <small># {omcDetail.shortcode}</small>
                </div>
            </div> */}
            <h3 className='mb-sm-0 mb-3'>RO Management</h3>
            <span className='d-flex'>
                <span className='position-relative me-3'>
                <input onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                className='form-control form-control-sm form-control-solid mw-200px'
                placeholder='Search..'
                /><i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i></span>
                <button className='btn btn-sm btn-primary me-2 text-nowrap' data-bs-toggle='modal' data-bs-target='#atom_Ro_add_modal' onClick={() => {
                    formik.resetForm();
                    setDeleteId('');
                    setSearch('');
                    setEditId({});
                }}>
                    <i className="bi bi-plus-lg"></i> Add RO
                </button>
            </span>
        </div>
        <div className='card-body pt-0 overflow-x-auto'>
            <div className='overflow-x-auto'> 
            <table
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' >
                <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th>Sl.No</th>
                        <th>OMC</th>
                        <th>State Office</th>
                        <th>Retail Office</th>
                        <th>Area Office</th>
                        <th>RO</th>
                        <th>Type</th>
                        <th className='text-center'>Actions</th>
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                    {ro.length > 0 ? pageData.map((item, i) => {
                        console.log("jertheriouteoiruteriout", JSON.parse(item.json_data));
                        
                    return(
                    <tr key={i}>
                        <td>{i+1+first}</td>
                        <td className='py-1'>
                            <div className='d-flex align-items-center'>
                                <div className='prof_img_pre_list border'>
                                {item.omc_logo ? 
                                    <img src={process.env.REACT_APP_API_URL+'/uploads/company/logo/'+item.omc_id+'/'+item.omc_logo} className='profile_image w-100'/> : 
                                    <p className='h-100 d-flex justify-content-center align-items-center bg-gray-300'>LOGO</p>}
                                </div>
                                <div className='d-flex flex-column ms-3'>
                                    <span>{item.omc_name}</span>
                                    <small className='text-nowrap'># {item.omc_shortcode}</small>
                                </div>
                            </div>
                        </td>
                        <td>
                            <span>{item.state_name}</span>
                        </td>
                        <td>
                            <span>{item.division_name}</span>
                        </td>
                        <td>
                            <span>{item.area_name}</span>
                        </td>
                        <td className='mw-200px'>
                            {item.json_data != null ?
                            <ul className=''>
                                {JSON.parse(item.json_data)?.map((data, j) => {
                                    console.log("glfuioiourriutehrtiuerh", data);                                    
                                    return(
                                        <li className='' key={j} >{data.ro_name} - {data.sap_code}</li>
                                    )
                                })}
                            </ul> : <></>}
                        </td>
                        <td className='mw-200px'>
                            <span className='two-line-limit'>{item.type}</span>
                        </td>
                        <td className='text-center text-nowrap'>
                            <button className='btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2' onClick={() => {
                                setEditId(item);
                                }}>
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                            <button className='btn btn-sm btn-secondary btn-active-color-danger btn-icon me-2' data-bs-toggle='modal' data-bs-target='#atom_Ro_delete_modal' onClick={() => setDeleteId(item)}>
                                <i className="bi bi-trash"></i>
                            </button>
                        </td>
                    </tr>)}) : 
                    <tr>
                        <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                        </div>
                        </td>
                    </tr>}
                </tbody>
            </table>
            </div>
            <div className='table_paginator mt-3 d-flex justify-content-end'>
                <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={ro.length}
                    rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    </div>}
    </>)
}

export default ManageRO;


