import react, {useState, useEffect} from 'react';
import { campaignGetById, deleteCampaign, getCampaign, getCampaignPrizeDetails, getRoDropdown, saveCampaign, updateCampaign, getRoleManagementList } from './core/_requests';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { PageTitle } from '../../../_metronic/layout/core/PageData';
import { Paginator } from 'primereact/paginator';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getAreas, getDivisions, getMaster, getPrize, getStates } from '../BussinesSettings/core/_requests';
import { getRo } from '../RO/core/_requests';
import { getAreaOffice, getDivisionOffice, getOmc, getStateOffice } from '../OMC/core/_request';
import moment from 'moment';
import Select from 'react-select';
import makeAnimated from "react-select/animated";
import Campaigns from './campaignPoster';
import { Link } from 'react-router-dom';
import PDFViewer from './pdfViewer';
import { useAuth } from '../../modules/auth';

const Campaign = () => {
    const {currentUser, logout} = useAuth();
    const [designation, setDesignation] = useState<any[]>([])
    const [permissions, setPermissions] = useState<any>({})
    const [campaign, setCampaign] = useState<any[]>([]);
    const [pageData, setPageData] = useState<any[]>([]);
    const [omc, setOmc] = useState<any[]>([]);
    const [divisions, setDivisions] = useState<any[]>([]);
    const [divisionDD, setDivisionDD] = useState<any[]>([]);
    const [stateDD, setStateDD] = useState<any[]>([]);
    const [areaDD, setAreaDD] = useState<any[]>([]);
    const [selectedArea, setSelectedArea] = useState<any[]>([]);
    const [ro, setRo] = useState<any[]>([]);
    const [selectedState, setSelectedState] = useState<any[]>([]);
    const [selectedDivision, setSelectedDivision] = useState<any[]>([]);
    const [selectedRo, setSelectedRo] = useState<any[]>([]);
    const [areas, setAreas] = useState<any[]>([]);
    const [states, setStates] = useState<any[]>([]);
    const [roDropList, setRoDropList] = useState<any[]>([]);
    const [CampaignForSearch, setCampaignForSearch] = useState<any[]>([]);
    const [vehicleType, setVehicleType] = useState<any[]>([]);
    const [fuelType, setFuelType] = useState<any[]>([]);
    const [fuelDropList, setFuelDropList] = useState<any[]>([]);
    const [selectedFuel, setSelectedFuel] = useState<any[]>([]);
    const [selectedVehicle, setSelectedVehicle] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [winnerList, setWinnerList] = useState<any>({});
    const [editId, setEditId] = useState<any>({});
    const [selectedOmc, setSelectedOmc] = useState<any>({});
    const [search, setSearch] = useState('');
    const [OMCId, setOMCId] = useState('');
    const [first, setFirst] = useState(0);
    const [sort, setSort] = useState(0);
    const [rows, setRows] = useState(10);
    const [imgPre, setImgPre] = useState(false);
    const [smsActive, setSmsActive] = useState(false);
    const [selectedPDFFile, setSelectedPDFFile] = useState(null);
    const [wiseWinner, setWiseWinner] = useState<any[]>(["campaign_wise"]);
    // const [prices, setPrices] = useState<any[]>([]);
    const [campaignPrices, setCampaignPrices] = useState<any[]>([{
        'campaign_id': '',
        'campaign_name': '',
        'prize': ''
    }]);
    const [areaPrices, setAreaPrices] = useState<any[]>([]);
    const [roPrices, setRoPrices] = useState<any[]>([]);
    const [prizesList, setprizeList] = useState<any[]>([]);
    const [imagePreview, setImagePreview] = useState<any>({
        "banner": null,
        "image1": null,
        "image2": null,
        "image3": null,
    });    
    const [winnerType, setWinnerType] = useState<any>({
        "campaign_winner": 'static',
        "area_winner": 'static',
        "ro_winner": 'static'
    });    
    const [fieldVal, setFieldVal] = useState<any>({
        "sloganField": false,
        "emailField": false,
        "vnumberField": false,
        "ftypeField": false,
        "vtypeField": false,
        "invoiceField": false,
        "mobileField": false,
        "nameField": false,
        "bill_amount": false,
        "bill_image": false,
        "selfi_image": false,
        "customer_attendant_name": false,
        "customer_attendant_code": false,
        "field_officer_name": false,
        "field_officer_code": false,
        "sloganFieldRequired": false,
        "emailFieldRequired": false,
        "vnumberFieldRequired": false,
        "ftypeFieldRequired": false,
        "vtypeFieldRequired": false,
        "invoiceFieldRequired": false,
        "mobileFieldRequired": false,
        "nameFieldRequired": false,
        "bill_amountRequired": false,
        "bill_imageRequired": false,
        "selfi_imageRequired": false,
        "customer_attendant_nameRequired": false,
        "customer_attendant_codeRequired": false,
        "field_officer_nameRequired": false,
        "field_officer_codeRequired": false,
    });
    const [imageFile, setImageFile] = useState<any>({
        "banner": null,
        "image1": null,
        "image2": null,
        "image3": null,
    }); 
    
    console.log("rtherugturtgeruitgr", wiseWinner);
    const userId = currentUser?.designation;
    const currentUserId = currentUser?.designation;
    console.log('currentUserId', currentUserId);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const CampaignList = async () => {
        setLoading(true);
        const response = await getCampaign();
        setCampaign(response.output);
        setCampaignForSearch(response.output);
        setLoading(false);
    }

    const prizeList = async () => {
        const response = await getPrize();
        setprizeList(response.output);
    }
    
    const OmcList = async () => {
        const response = await getOmc();
        setOmc(response.output);
    }

    const statesList = async () => {
        const response = await getStateOffice();
        setStates(response.output);
    }

    const divisionList = async () => {
        const response = await getDivisionOffice();
        setDivisions(response.output);
    }

    const areaList = async () => {
        const response = await getAreaOffice();
        setAreas(response.output);
    }

    const RoList = async () => {
        const response = await getRoDropdown();
        setRo(response.output);        
    }

    const vehicleTypeList = async () => {
        const response = await getMaster('vehicle_type');
        setVehicleType(response.output);
    }

    const fuelTypeList = async () => {
        const response = await getMaster('fuel_type');
        setFuelType(response.output);
    }

    // useEffect(() => {
    //     if(roDropList.length > 0) {
    //        let befjgwk:any = [];
    //        for(let a in roDropList) {
    //            let djd =  JSON.parse(roDropList[a]?.ro_name);
    //            console.log("ltiouryeruiweur"+a, djd);           
    //         for(let b in djd) {
    //             befjgwk.push(djd[b]);
    //         }
    //        }
    //     }
    // }, [roDropList]);    

    const initialValues = {
        "name": '',
        "omc_id": '',
        "ro_id": '',
        "state": '',
        "division": '',
        "area": '',
        "start_date": '',
        "end_date": '',
        "slogon": '',
        "vehicle_type": '',
        "fuel_type": '',
        "terms_conditions": '',
        "value": '',
        "preview": '',
        "banner": '',
        "image1": '',
        "image2": '',
        "image3": '',
        "price_amoung": '',
        "winner_type": '',

        "sloganField": false,
        "emailField": false,
        "vnumberField": false,
        "ftypeField": false,
        "vtypeField": false,
        "invoiceField": false,
        "mobileField": false,
        "nameField": false,
        "bill_amount":false,
        "bill_image":false,
        "selfi_image":false,
        "customer_attendant_name":false,
        "customer_attendant_code":false,
        "field_officer_name":false,
        "field_officer_code":false,
        "sloganFieldRequired": false,
        "emailFieldRequired": false,
        "vnumberFieldRequired": false,
        "ftypeFieldRequired": false,
        "vtypeFieldRequired": false,
        "invoiceFieldRequired": false,
        "mobileFieldRequired": false,
        "nameFieldRequired": false,
        "bill_amountRequired":false,
        "bill_imageRequired":false,
        "selfi_imageRequired":false,
        "customer_attendant_nameRequired":false,
        "customer_attendant_codeRequired":false,
        "field_officer_nameRequired":false,
        "field_officer_codeRequired":false,
    }

    const campaignSchema = Yup.object().shape({
        name: Yup.string()
            .required('Campaign Name is required'),
        omc_id: Yup.string()
            .required('OMC is required'),
        start_date: Yup.string()
            .required('Start date is required'),
        end_date: Yup.string()
            .required('End date is required'),
        ro_id: Yup.string()
            .required('RO is required'),
        state: Yup.string()
            .required('State is required'),
        division: Yup.string()
            .required('Division is required'),
        area: Yup.string()
            .required('Area is required'),
        fuel_type: Yup.string()
            .required('Fuel type is required'),
        vehicle_type: Yup.string()
            .required('Vehicle type is required'),
    })

    const isValidFileUploaded=(file:any)=>{
        const validExtensions = ['png','jpeg','jpg']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
      }

    const handleImagePreview = (e:any) => {
        if(e.target.files[0].size > 10485760){
            toast.error("Image size should be below 10MB !", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            (document.getElementById(e.target.name) as HTMLInputElement).value = '';
            return;
        } else {
            const file = e.target.files[0];
            if(isValidFileUploaded(file)){
                let image_as_base64:any = URL.createObjectURL(e.target.files[0]);
                let image_as_files:any = e.target.files[0];            
                setImageFile({...imageFile, [e.target.name]: image_as_files});
                setImagePreview({...imagePreview, [e.target.name]: image_as_base64});
                setImgPre(true);
           } else { 
            (document.getElementById(e.target.name) as HTMLInputElement).value = '';
            toast.error("Image should be .jpg, .jpeg and .png only!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false, 
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
           }  
        }
    }

    const imgRemove = () => {
        setTimeout(() => {
        setImageFile({
            "banner": null,
            "image1": null,
            "image2": null,
            "image3": null,
        });
        setImagePreview({
            "banner": null,
            "image1": null,
            "image2": null,
            "image3": null,
        });
        setImgPre(false);
        }, 500);
    }

    const formik = useFormik({
        initialValues,
        validationSchema: campaignSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
            const formData = new FormData();

            let prices = {
                "campaign": campaignPrices,
                "area": areaPrices,
                "ro": roPrices,
            }

            console.log("ergiwuruywewrhtiegrowieur", prices);
            

            let formBuilder = {
                "sloganField": fieldVal.sloganField,
                "emailField": fieldVal.emailField,
                "vnumberField": fieldVal.vnumberField,
                "ftypeField": fieldVal.ftypeField,
                "vtypeField": fieldVal.vtypeField,
                "invoiceField": fieldVal.invoiceField,
                "mobileField": fieldVal.mobileField,
                "nameField": fieldVal.nameField,
                "bill_amount": fieldVal.bill_amount,
                "bill_image": fieldVal.bill_image,
                "selfi_image": fieldVal.selfi_image,
                "customer_attendant_name": fieldVal.customer_attendant_name,
                "customer_attendant_code": fieldVal.customer_attendant_code,
                "field_officer_name": fieldVal.field_officer_name,
                "field_officer_code": fieldVal.field_officer_code,
                "sloganFieldRequired": fieldVal.sloganFieldRequired,
                "emailFieldRequired": fieldVal.emailFieldRequired,
                "vnumberFieldRequired": fieldVal.vnumberFieldRequired,
                "ftypeFieldRequired": fieldVal.ftypeFieldRequired,
                "vtypeFieldRequired": fieldVal.vtypeFieldRequired,
                "invoiceFieldRequired": fieldVal.invoiceFieldRequired,
                "mobileFieldRequired": fieldVal.mobileFieldRequired,
                "nameFieldRequired": fieldVal.nameFieldRequired,
                "bill_amountRequired": fieldVal.bill_amountRequired,
                "bill_imageRequired": fieldVal.bill_imageRequired,
                "selfi_imageRequired": fieldVal.selfi_imageRequired,
                "customer_attendant_nameRequired": fieldVal.customer_attendant_nameRequired,
                "customer_attendant_codeRequired":fieldVal.customer_attendant_codeRequired,
                "field_officer_nameRequired": fieldVal.field_officer_nameRequired,
                "field_officer_codeRequired": fieldVal.field_officer_codeRequired,
            }
            
            formData.append("sms_active", smsActive ? '1' : '0');
            formData.append("name", values.name);
            formData.append("omc_id", values.omc_id);
            formData.append("ro_id", values.ro_id);
            formData.append("state", values.state);
            formData.append("division", values.division);
            formData.append("area", values.area);
            formData.append("start_date", values.start_date);
            formData.append("end_date", values.end_date);
            formData.append("slogon", values.slogon);
            selectedPDFFile && formData.append("terms_conditions", selectedPDFFile!);
            // formData.append("terms_conditions", values.terms_conditions);
            formData.append("value", values.value);
            formData.append("preview", values.preview);
            formData.append("vehicle_type", values.vehicle_type);
            formData.append("fuel_type", values.fuel_type);
            formData.append("winner_type", JSON.stringify(winnerType));
            formData.append("prize_wise", wiseWinner?.join(','));
            formData.append("prizes", JSON.stringify(prices));
            formData.append("forms", JSON.stringify(formBuilder));
            imageFile?.banner && formData.append("banner", imageFile?.banner);
            imageFile?.image1 && formData.append("image1", imageFile?.image1);
            imageFile?.image2 && formData.append("image2", imageFile?.image2);
            imageFile?.image3 && formData.append("image3", imageFile?.image3);

            let response;
            if(editId && editId.id != undefined) {                
                response = await updateCampaign(editId.id, formData)
            } else {
                response = await saveCampaign(formData)
            }    

            if(response.status == 200) {
                setLoading(false);                
                resetForm();
                setSelectedState([]);
                setSelectedDivision([]);
                setSelectedArea([]);
                setSelectedRo([]);
                setImageFile({
                    "banner": null,
                    "image1": null,
                    "image2": null,
                    "image3": null,
                });
                setImagePreview({
                    "banner": null,
                    "image1": null,
                    "image2": null,
                    "image3": null,
                });
                setImgPre(false);
                setSmsActive(false);
                setFieldVal({
                    "sloganField": false,
                    "emailField": false,
                    "vnumberField": false,
                    "ftypeField": false,
                    "vtypeField": false,
                    "invoiceField": false,
                    "mobileField": false,
                    "nameField": false,
                    "bill_amount":false,
                    "bill_image":false,
                    "selfi_image":false,
                    "customer_attendant_name":false,
                    "customer_attendant_code":false,
                    "field_officer_name":false,
                    "field_officer_code":false,
                    "sloganFieldRequired": false,
                    "emailFieldRequired": false,
                    "vnumberFieldRequired": false,
                    "ftypeFieldRequired": false,
                    "vtypeFieldRequired": false,
                    "invoiceFieldRequired": false,
                    "mobileFieldRequired": false,
                    "nameFieldRequired": false,
                    "bill_amountRequired":false,
                    "bill_imageRequired":false,
                    "selfi_imageRequired":false,
                    "customer_attendant_nameRequired":false,
                    "customer_attendant_codeRequired":false,
                    "field_officer_nameRequired":false,
                    "field_officer_codeRequired":false,
                });
                CampaignList();
                document.getElementById('atom_Campaign_add_modal_close')?.click();
                toast.success(response.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
          } catch (error) {
            console.error(error)
            toast.error("Somethig went wrong, Please try after sometimes!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setStatus('Server Error')
            setSubmitting(false)
            setLoading(false)
          }
        },
    })

    const handleDelete = async () => {
        const response = await deleteCampaign(deleteId)
        if(response.status == 200) {
            setDeleteId('');
            document.getElementById('atom_Campaign_delete_modal_close')?.click();
            CampaignList();
            toast.success(response.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(response.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
          setSelectedPDFFile(file);
        } else {
          alert('Please select a PDF file.');
        }
    };

      const editCampaignValues = async () => {
        const response = await campaignGetById(editId.id);
            console.log("dfhgisufteuweriwegr", response.output);
            let campaignData = response.output[0];
            document.getElementById('ewrtyweriugw7r543274t2736g32ty3v4h')?.click();
            // let data = ro?.filter((it) => it.omc_id?.toString() == editId.omc_id?.toString());
            // setRoDropList(data);            
            let fuel = fuelType?.filter((it) => it.omc_id?.toString().includes(campaignData?.omc_id?.toString()));
            setFuelDropList(fuel);
            setSmsActive(campaignData?.sms_active == 1 ? true : false);
            formik.setFieldValue('start_date', moment(campaignData?.start_date).format('YYYY-MM-DD'));
            formik.setFieldValue('end_date', moment(campaignData?.end_date).format('YYYY-MM-DD'));
            formik.setFieldValue('slogon', campaignData?.slogon);
            formik.setFieldValue('terms_conditions', campaignData?.terms_conditions);
            formik.setFieldValue('value', campaignData?.value);
            formik.setFieldValue('name', campaignData?.name);
            formik.setFieldValue('omc_id', campaignData?.omc_id);
            formik.setFieldValue('ro_id', campaignData?.ro_id);
            formik.setFieldValue('state', campaignData?.state);
            formik.setFieldValue('division', campaignData?.division);
            formik.setFieldValue('area', campaignData?.area);
            formik.setFieldValue('fuel_type', campaignData?.fuel_type);
            formik.setFieldValue('vehicle_type', campaignData?.vehicle_type);
            // formik.setFieldValue('price_amoung', campaignData?.prize_wise);
            // formik.setFieldValue('winner_type', campaignData?.winner_type);
            setWinnerType(JSON.parse(campaignData?.winner_type));
            if(campaignData?.forms) {
                const forms = JSON.parse(campaignData?.forms);
                setFieldVal({
                    "sloganField": forms.sloganField,
                    "emailField": forms.emailField,
                    "vnumberField": forms.vnumberField,
                    "ftypeField": forms.ftypeField,
                    "vtypeField": forms.vtypeField,
                    "invoiceField": forms.invoiceField,
                    "mobileField": forms.mobileField,
                    "nameField": forms.nameField,
                    "bill_amount": forms.bill_amount,
                    "bill_image": forms.bill_image,
                    "selfi_image": forms.selfi_image,
                    "customer_attendant_name": forms.customer_attendant_name,
                    "customer_attendant_code": forms.customer_attendant_code,
                    "field_officer_name": forms.field_officer_name,
                    "field_officer_code": forms.field_officer_code,
                    "sloganFieldRequired": forms.sloganFieldRequired,
                    "emailFieldRequired": forms.emailFieldRequired,
                    "vnumberFieldRequired": forms.vnumberFieldRequired,
                    "ftypeFieldRequired": forms.ftypeFieldRequired,
                    "vtypeFieldRequired": forms.vtypeFieldRequired,
                    "invoiceFieldRequired": forms.invoiceFieldRequired,
                    "mobileFieldRequired": forms.mobileFieldRequired,
                    "nameFieldRequired": forms.nameFieldRequired,
                    "bill_amountRequired": forms.bill_amountRequired,
                    "bill_imageRequired": forms.bill_imageRequired,
                    "selfi_imageRequired": forms.selfi_imageRequired,
                    "customer_attendant_nameRequired": forms.customer_attendant_nameRequired,
                    "customer_attendant_codeRequired":forms.customer_attendant_codeRequired,
                    "field_officer_nameRequired": forms.field_officer_nameRequired,
                    "field_officer_codeRequired": forms.field_officer_codeRequired,
                })
            }
            // if(campaignData?.prizes) {
            //     let prizes:any = JSON.parse(campaignData?.prizes);
            //     console.log("ekweryeriuywirueywoieury", prizes);
                
            //     setPrices(prizes);
            // }
            setImagePreview({
                "banner": campaignData?.banner ? process.env.REACT_APP_API_URL+'/uploads/campaign/banner/'+campaignData?.id+'/'+campaignData?.banner : null,
                "image1": campaignData?.image1 ? process.env.REACT_APP_API_URL+'/uploads/campaign/image1/'+campaignData?.id+'/'+campaignData?.image1 : null,
                "image2": campaignData?.image2 ? process.env.REACT_APP_API_URL+'/uploads/campaign/image2/'+campaignData?.id+'/'+campaignData?.image2 : null,
                "image3": campaignData?.image3 ? process.env.REACT_APP_API_URL+'/uploads/campaign/image3/'+campaignData?.id+'/'+campaignData?.image3 : null,
            })
            setStateDD(states.filter(item => campaignData?.omc_id?.toString() === item.omc_id?.toString()));
            setDivisionDD(divisions?.filter(item => campaignData?.omc_id?.toString() === item.omc_id?.toString()));
            setAreaDD(areas?.filter(item => campaignData?.omc_id?.toString() === item.omc_id?.toString()));
            setRoDropList(ro?.filter(item => campaignData?.omc_id?.toString() === item.omc_id?.toString()));
            setTimeout(() => {
            setSelectedState(states?.filter((val:any) => campaignData?.state?.split(',')?.indexOf(val.id?.toString()) !== -1));
            setSelectedDivision(divisions?.filter((val:any) => campaignData?.division?.split(',')?.indexOf(val.id?.toString()) !== -1));
            setSelectedArea(areas?.filter((val:any) => campaignData?.area?.split(',')?.indexOf(val.id?.toString()) !== -1));
            setSelectedRo(ro?.filter((val:any) => campaignData?.ro_id?.split(',')?.indexOf(val.id?.toString()) !== -1));
            setSelectedFuel(fuelType?.filter((val:any) => campaignData?.fuel_type?.split(',')?.indexOf(val.id?.toString()) !== -1));
            setSelectedVehicle(vehicleType?.filter((val:any) => campaignData?.vehicle_type?.split(',')?.indexOf(val.id?.toString()) !== -1));
            }, 500)
            setTimeout(() => {
                setWiseWinner(campaignData?.prize_wise?.split(','));
                console.log("rheoriuteorteoirthejth", campaignData?.prizes);
                let kfjgwergh = JSON.parse(campaignData?.prizes)
                console.log("ltheiruteriuteroiuter", campaignData?.prize_wise);
                
                if(campaignData?.prize_wise?.split(',').find((item) => item == "campaign_wise")) {
                    let prize:any = {
                        'campaign_id': campaignData?.id || '',
                        'campaign_name': campaignData?.name || formik.values.name,
                        'prize': ''
                    }
                    setCampaignPrices(kfjgwergh?.campaign);
                }
                if(campaignData?.prize_wise?.split(',').find((item) => item == "area_wise")) {
                    let areaPrizes: any = [];
                    (areas?.filter((val:any) => campaignData?.area?.split(',')?.indexOf(val.id?.toString()) !== -1))?.map((area:any) => areaPrizes.push({
                        'area_id': area.id,
                        'area_name': area.city,
                        'prize': ''
                    }));
                    setAreaPrices(kfjgwergh?.area);
                }
                if(campaignData?.prize_wise?.split(',').find((item) => item == "ro_wise")) {
                    let roPrizes: any = [];
                    (ro?.filter((val:any) => campaignData?.ro_id?.split(',')?.indexOf(val.id?.toString()) !== -1))?.map((area:any) => roPrizes.push({
                        'ro_id': area.id,
                        'ro_name': area.ro_name,
                        'prize': ''
                    }));
                    setRoPrices(kfjgwergh?.ro);
                }
            }, 1500);
      }

      const DesignationList =  async () => {
        const DesignationResponse = await getRoleManagementList()
        setDesignation(DesignationResponse.output);
        setPermissions(DesignationResponse.output?.find((item:any) => item.designation == currentUserId)) 
        console.log('DesignationResponse.output', DesignationResponse.output);
        console.log('Designation permissions', DesignationResponse.output?.find((item:any) => item.designation == currentUserId));
        console.log('permissions', permissions);

    }

    useEffect(() => {
        if(editId && editId.id != undefined) {
            editCampaignValues();
        }
    }, [editId])

    useEffect(() => {
        if(search?.length > 0) {
            const campaign = CampaignForSearch.filter((p) => p.omc_name?.toLowerCase().includes(search) || p.omc_shortcode?.toLowerCase().includes(search) || p.name?.toLowerCase().includes(search) || p.state_name?.toLowerCase().includes(search) || p.division_name?.toLowerCase().includes(search) || p.area_name?.toLowerCase().includes(search) || p.ro_name?.toLowerCase().includes(search));
            setCampaign(campaign);
        } else {
            setCampaign(CampaignForSearch);
        }
    }, [search]);

    useEffect(() => {
        if(OMCId) {
            const campaign = CampaignForSearch.filter((p) => p.omc_id?.toString() == OMCId);
            setCampaign(campaign);
        } else {
            setCampaign(CampaignForSearch);
        }
    }, [OMCId]);

    useEffect(() => {
        CampaignList();
        OmcList();
        statesList();
        divisionList();
        areaList();
        RoList();
        vehicleTypeList();
        fuelTypeList();
        prizeList();
        DesignationList();
    }, []);
    
    useEffect(() => {        
        let page = campaign?.slice(first, first+rows);
        setPageData(page);
    }, [first, rows, campaign]);    

    useEffect(() => {
        if(sort == 1) {
            let atoz = campaign?.sort((a, b) => {
                return a.name.localeCompare(b.name);
              });
              setCampaign(atoz);
              let page = atoz?.slice(first, first+rows);
              setPageData(page);
        } else if (sort == 2) {
            let ztoa = campaign?.sort((a, b) => {
                return b.name.localeCompare(a.name);
              });
              setCampaign(ztoa);
              let page = ztoa?.slice(first, first+rows);
              setPageData(page);
        } else {
            CampaignList();
        }
    }, [sort]);

  return (<>
  <PageTitle>Manage Campaign</PageTitle>
  <button type="button" className="d-none" data-bs-toggle='modal' data-bs-target='#atom_Campaign_add_modal' id='ewrtyweriugw7r543274t2736g32ty3v4h'>open</button>
    <div className='modal fade' id='atom_Campaign_add_modal' aria-hidden='true' data-bs-keyboard="false" data-bs-backdrop="static">
      <div className='modal-dialog mw-650px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center border-bottom'>
            <div className='text-center'>
              <h3 className='mb-3'>{editId && editId.id != undefined ? "Update Campaign" : "Add Campaign"}</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_Campaign_add_modal_close' onClick={() => {
                formik.resetForm();
                imgRemove();
                setFieldVal({
                    "sloganField": false,
                    "emailField": false,
                    "vnumberField": false,
                    "ftypeField": false,
                    "vtypeField": false,
                    "invoiceField": false,
                    "mobileField": false,
                    "nameField": false,
                    "bill_amount":false,
                    "bill_image":false,
                    "selfi_image":false,
                    "customer_attendant_name":false,
                    "customer_attendant_code":false,
                    "field_officer_name":false,
                    "field_officer_code":false,
                    "sloganFieldRequired": false,
                    "emailFieldRequired": false,
                    "vnumberFieldRequired": false,
                    "ftypeFieldRequired": false,
                    "vtypeFieldRequired": false,
                    "invoiceFieldRequired": false,
                    "mobileFieldRequired": false,
                    "nameFieldRequired": false,
                    "bill_amountRequired":false,
                    "bill_imageRequired":false,
                    "selfi_imageRequired":false,
                    "customer_attendant_nameRequired":false,
                    "customer_attendant_codeRequired":false,
                    "field_officer_nameRequired":false,
                    "field_officer_codeRequired":false,
                });
                setDeleteId('');
                setSearch('');
                setEditId({});
                setSmsActive(false);
            }}>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y p-10 mh-650px'>
            <form noValidate onSubmit={formik.handleSubmit}>
                <div className="row mb-4">
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">Campaign Name</label>
                        <div className="input-group first mb-3 input_prepend">
                            <input type="text" {...formik.getFieldProps('name')}
                                className="form-control" placeholder="Enter your Name.." />
                        </div>
                        {formik.touched.name && formik.errors.name && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.name}</span>
                                </div>
                            </div>
                        )}
                    </div>   
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">OMC</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select {...formik.getFieldProps('omc_id')} className="form-control" onChange={(e) => {
                                formik.setFieldValue('omc_id', e.target.value);
                                setSelectedOmc(omc.find((com) => e.target.value == com.id))
                                let fuel = fuelType.filter((it) => it.omc_id?.toString().includes(e.target.value?.toString()));
                                setFuelDropList(fuel);
                                setStateDD(states.filter((com) => e.target.value == com.omc_id));
                                setRoDropList(ro.filter((com) => e.target.value == com.omc_id && stateDD?.some(item2 => item2.id === com.state) && divisionDD?.some(item2 => item2.id === com.division) && areaDD?.some(item2 => item2.id === com.area)));
                                setSelectedState([]);
                                setSelectedRo([]);
                                setSelectedDivision([]);
                                setSelectedArea([]);
                                setSelectedVehicle([]);
                                setSelectedFuel([]);
                            }} >
                                <option value="" >select OMC</option>
                                {omc.map((omc, i) => {
                                    return(
                                        <option value={omc.id} key={i} >{omc.name}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {formik.touched.omc_id && formik.errors.omc_id && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.omc_id}</span>
                                </div>
                            </div>
                        )}
                    </div>   
                    <div className="col-md-6 col-12 mb-3">
                        <div className='d-flex justify-content-between'>
                            <label htmlFor="basic-url" className="form-label required">State</label>
                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label className="form-check-label me-2" htmlFor="stateRadioLg">
                                    <small>select all</small>
                                </label>
                                <input className="form-check-input" type="checkbox" id="stateRadioLg" checked={stateDD?.length != 0 && stateDD?.length == selectedState?.length ? true : false} onChange={(e) => {
                                    if(e.target.checked) {
                                        setSelectedState(stateDD);
                                        formik.setFieldValue('state', stateDD.map((data) => data.id)?.join(','));
                                        setDivisionDD(divisions?.filter(item => stateDD?.some(item2 => item2.id === item.state)));
                                        setAreaDD(areas?.filter(item => stateDD?.some(item2 => item2.id === item.state) && divisionDD?.some(item2 => item2.id === item.division))); 
                                        setRoDropList(ro.filter(item => stateDD?.some(item2 => item2.id === item.state) && divisionDD?.some(item2 => item2.id === item.division) && areaDD?.some(item2 => item2.id === item.area)));
                                        setSelectedDivision(divisions?.filter(item => stateDD?.some(item2 => item2.id === item.state))?.filter((item:any) => selectedDivision?.indexOf(item) !== -1));
                                        setSelectedArea(areas?.filter(item => stateDD?.some(item2 => item2.id === item.state))?.filter((item:any) => selectedArea?.indexOf(item) !== -1));
                                    } else {
                                        setSelectedState([]);
                                        formik.setFieldValue('state', '');
                                        setDivisionDD([]);
                                        setAreaDD([]); 
                                        setRoDropList([]); 
                                        setSelectedDivision([]);
                                        setSelectedArea([]);
                                        setSelectedRo([]);
                                    }
                                }}/>                            
                            </div>
                        </div>
                        <div className="input-group first mb-3 input_prepend">
                            <Select
                                isMulti
                                options={stateDD}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option:any) => option.state}
                                getOptionValue={(option:any) => option.id}
                                value={stateDD?.filter((item:any) => selectedState?.indexOf(item) !== -1)}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val:any) => {  
                                    console.log("qejriuweroiugweoriugweuior", val);
                                    setSelectedState(val);  
                                    formik.setFieldValue('state', val.map((data) => data.id)?.join(','));
                                    setDivisionDD(divisions?.filter(item => val?.some(item2 => item2.id === item.state)));
                                    setAreaDD(areas?.filter(item => val?.some(item2 => item2.id === item.state) && divisionDD?.some(item2 => item2.id === item.division))); 
                                    setRoDropList(ro.filter(item => val?.some(item2 => item2.id === item.state) && divisionDD?.some(item2 => item2.id === item.division) && areaDD?.some(item2 => item2.id === item.area)));
                                    setSelectedDivision(divisions?.filter(item => val?.some(item2 => item2.id === item.state))?.filter((item:any) => selectedDivision?.indexOf(item) !== -1));
                                    setSelectedArea(areas?.filter(item => val?.some(item2 => item2.id === item.state))?.filter((item:any) => selectedArea?.indexOf(item) !== -1));
                                }}
                                placeholder={"select.."}
                            />
                        </div>
                        {formik.touched.state && formik.errors.state && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.state}</span>
                                </div>
                            </div>
                        )}
                    </div>   
                    <div className="col-md-6 col-12 mb-3">
                        <div className='d-flex justify-content-between'>
                        <label htmlFor="basic-url" className="form-label required">{divisionDD[0]?.type || 'Retail'}</label>
                        <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label className="form-check-label me-2" htmlFor="divRadioLg">
                                    <small>select all</small>
                                </label>
                                <input className="form-check-input" type="checkbox" id="divRadioLg" checked={divisionDD?.length != 0 && divisionDD?.length == selectedDivision?.length ? true : false} onChange={(e) => {
                                    if(e.target.checked) {
                                        setSelectedDivision(divisionDD);
                                        formik.setFieldValue('division', divisionDD.map((data) => data.id)?.join(','));
                                        setAreaDD(areas?.filter(item => divisionDD?.some(item2 => item2.id === item.division)));
                                        setRoDropList(ro?.filter(item => divisionDD?.some(item2 => item2.id === item.division) && areaDD?.some(item2 => item2.id === item.area)));
                                        setSelectedArea(areas?.filter(item => divisionDD?.some(item2 => item2.id === item.division))?.filter((item:any) => selectedArea?.indexOf(item) !== -1)); 
                                    } else {
                                        setSelectedDivision([]);
                                        formik.setFieldValue('division', '');
                                        setAreaDD([]);
                                        setSelectedArea([]); 
                                    }
                                }}/>
                            </div>
                        </div>
                        <div className="input-group first mb-3 input_prepend">
                            <Select
                                isMulti
                                options={divisionDD}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option:any) => option.division}
                                getOptionValue={(option:any) => option.id}
                                value={divisionDD?.filter((item:any) => selectedDivision?.indexOf(item) !== -1)}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val:any) => {  
                                    setSelectedDivision(val);    
                                    formik.setFieldValue('division', val.map((data) => data.id)?.join(','));
                                    setAreaDD(areas?.filter(item => val?.some(item2 => item2.id === item.division)));
                                    setRoDropList(ro?.filter(item => val?.some(item2 => item2.id === item.division) && areaDD?.some(item2 => item2.id === item.area)));
                                    setSelectedArea(areas?.filter(item => val?.some(item2 => item2.id === item.division))?.filter((item:any) => selectedArea?.indexOf(item) !== -1));
                                }}
                                placeholder={"select.."}
                            />
                        </div>
                        {formik.touched.division && formik.errors.division && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.division}</span>
                                </div>
                            </div>
                        )}
                    </div>   
                    <div className="col-md-6 col-12 mb-3">
                        <div className='d-flex justify-content-between'>
                        <label htmlFor="basic-url" className="form-label required">{areaDD[0]?.type || 'Area'}</label>
                            <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label className="form-check-label me-2" htmlFor="areaRadioLg">
                                    <small>select all</small>
                                </label>
                                <input className="form-check-input" type="checkbox" id="areaRadioLg" checked={areaDD?.length != 0 && areaDD?.length == selectedArea?.length ? true : false} onChange={(e) => {
                                    if(e.target.checked) {
                                        setSelectedArea(areaDD);
                                        formik.setFieldValue('area', areaDD.map((data) => data.id)?.join(','));
                                        setRoDropList(ro?.filter(item => areaDD?.some(item2 => item2.id?.toString() === item.area?.toString())));
                                    } else {
                                        setSelectedArea([]);
                                        setRoDropList([]);
                                        formik.setFieldValue('area', '');
                                    }
                                }}/>
                            </div>
                        </div>
                        <div className="input-group first mb-3 input_prepend">
                            <Select
                                isMulti
                                options={areaDD}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option:any) => option.city}
                                getOptionValue={(option:any) => option.id}
                                value={areaDD?.filter((item:any) => selectedArea?.indexOf(item) !== -1)}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val:any) => {  
                                    setSelectedArea(val);    
                                    formik.setFieldValue('area', val.map((data) => data.id)?.join(','));  
                                    setRoDropList(ro?.filter(item => val?.some(item2 => item2.id === item.area)));
                                }}
                                placeholder={"select.."}
                            />
                        </div>
                        {formik.touched.area && formik.errors.area && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.area}</span>
                                </div>
                            </div>
                        )}
                    </div> 
                    <div className="col-md-6 col-12 mb-3">
                        <div className='d-flex justify-content-between'>
                        <label htmlFor="basic-url" className="form-label required">{roDropList[0]?.ro_type || 'RO'}</label>
                        <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label className="form-check-label me-2" htmlFor="roRadioLg">
                                    <small>select all</small>
                                </label>
                                <input className="form-check-input" type="checkbox" id="roRadioLg" checked={roDropList?.length != 0 && roDropList?.length == selectedRo?.length ? true : false} onChange={(e) => {
                                    if(e.target.checked) {
                                        setSelectedRo(roDropList);
                                        formik.setFieldValue('ro_id', roDropList.map((data) => data.id)?.join(','));
                                    } else {
                                        setSelectedRo([]);
                                        formik.setFieldValue('ro_id', '');
                                    }
                                }}/>
                            </div>
                        </div>
                        <div className="input-group first mb-3 input_prepend">
                            <Select
                                isMulti
                                options={roDropList}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option:any) => option.ro_name}
                                getOptionValue={(option:any) => option.id}
                                value={roDropList?.filter((item:any) => selectedRo?.indexOf(item) !== -1)}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val:any) => {  
                                    setSelectedRo(val);    
                                    formik.setFieldValue('ro_id', val.map((data) => data.id)?.join(','));
                                }}
                                placeholder={"select.."}
                            />
                        </div>
                        {formik.touched.ro_id && formik.errors.ro_id && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.ro_id}</span>
                                </div>
                            </div>
                        )}
                    </div> 
                    <div className="col-md-6 col-12 mb-3">
                        <div className='d-flex justify-content-between'>
                        <label htmlFor="basic-url" className="form-label required">Fuel Type</label>
                        <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label className="form-check-label me-2" htmlFor="fuelRadioLg">
                                    <small>select all</small>
                                </label>
                                <input className="form-check-input" type="checkbox" id="fuelRadioLg" checked={fuelDropList?.length != 0 && fuelDropList?.length == selectedFuel?.length ? true : false} onChange={(e) => {
                                    if(e.target.checked) {
                                        setSelectedFuel(fuelDropList);
                                        formik.setFieldValue('fuel_type', fuelDropList.map((data) => data.id)?.join(','));
                                    } else {
                                        setSelectedFuel([]);
                                        formik.setFieldValue('fuel_type', '');
                                    }
                                }}/>
                            </div>
                        </div>
                        <div className="input-group first mb-3 input_prepend">
                            <Select
                                isMulti
                                options={fuelDropList}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option:any) => option.option_value}
                                getOptionValue={(option:any) => option.id}
                                value={fuelDropList?.filter((item:any) => selectedFuel?.indexOf(item) !== -1)}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val:any) => {  
                                    setSelectedFuel(val);    
                                    formik.setFieldValue('fuel_type', val.map((data) => data.id)?.join(','));                                         
                                }}
                                placeholder={"select.."}
                            />
                        </div>
                        {formik.touched.fuel_type && formik.errors.fuel_type && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.fuel_type}</span>
                                </div>
                            </div>
                        )}
                    </div> 
                    <div className="col-md-6 col-12 mb-3">
                        <div className='d-flex justify-content-between'>
                        <label htmlFor="basic-url" className="form-label required">Vehicle Type</label>
                        <div className="form-check form-check-custom form-check-solid form-check-sm">
                                <label className="form-check-label me-2" htmlFor="vehicleRadioLg">
                                    <small>select all</small>
                                </label>
                                <input className="form-check-input" type="checkbox" id="vehicleRadioLg" checked={vehicleType?.length != 0 && vehicleType?.length == selectedVehicle?.length ? true : false} onChange={(e) => {
                                    if(e.target.checked) {
                                        setSelectedVehicle(vehicleType);
                                        formik.setFieldValue('vehicle_type', vehicleType.map((data) => data.id)?.join(','));
                                    } else {
                                        setSelectedVehicle([]);
                                        formik.setFieldValue('vehicle_type', '');
                                    }
                                }}/>
                            </div>
                        </div>
                        <div className="input-group first mb-3 input_prepend">
                            <Select
                                isMulti
                                options={vehicleType}
                                closeMenuOnSelect={false}
                                components={makeAnimated()}
                                getOptionLabel={(option:any) => option.option_value}
                                getOptionValue={(option:any) => option.id}
                                value={vehicleType?.filter((item:any) => selectedVehicle?.indexOf(item) !== -1)}
                                classNamePrefix=""
                                className={"w-100 "}
                                onChange={(val:any) => {  
                                    setSelectedVehicle(val);    
                                    formik.setFieldValue('vehicle_type', val.map((data) => data.id)?.join(','));                                         
                                }}
                                placeholder={"select.."}
                            />
                        </div>
                        {formik.touched.vehicle_type && formik.errors.vehicle_type && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.vehicle_type}</span>
                                </div>
                            </div>
                        )}
                    </div> 
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">Start Date</label>
                        <div className="input-group mb-3">
                            <input type="date" {...formik.getFieldProps('start_date')} className="form-control" />
                        </div>
                        {formik.touched.start_date && formik.errors.start_date && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.start_date}</span>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-6 col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label required">End Date</label>
                        <div className="input-group mb-3">
                            <input type="date" min={formik.values.start_date} {...formik.getFieldProps('end_date')} className="form-control" />
                        </div>
                        {formik.touched.end_date && formik.errors.end_date && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert' className='text-danger'>{formik.errors.end_date}</span>
                                </div>
                            </div>
                        )}
                    </div>                                                                        
                    <div className="col-12 mb-3"> 
                        <div className='row'>
                            <div className='col-md-8' >
                                <label htmlFor="basic-url" className="form-label">Banner</label>
                                <div className="input-group mb-3">
                                    <input type="file" className="form-control" name='banner' id='banner' onChange={(e) => handleImagePreview(e)} />
                                </div>
                            </div>
                            <div className='col-md-4 d-flex justify-content-center'>
                                <div className='campaign_form_pre' >
                                    <img src={imagePreview.banner} className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div>                                                                        
                    <div className="col-12 mb-3"> 
                        <div className='row'>
                            <div className='col-md-8' >
                                <label htmlFor="basic-url" className="form-label">Image 1</label>
                                <div className="input-group mb-3">
                                    <input type="file" className="form-control" name='image1' id='image1' onChange={(e) => handleImagePreview(e)} />
                                </div>
                            </div>
                            <div className='col-md-4 d-flex justify-content-center'>
                                <div className='campaign_form_pre' >
                                    <img src={imagePreview.image1} className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div>                                                                        
                    <div className="col-12 mb-3"> 
                        <div className='row'>
                            <div className='col-md-8' >
                                <label htmlFor="basic-url" className="form-label">Image 2</label>
                                <div className="input-group mb-3">
                                    <input type="file" className="form-control" name='image2' id='image2' onChange={(e) => handleImagePreview(e)} />
                                </div>
                            </div>
                            <div className='col-md-4 d-flex justify-content-center'>
                                <div className='campaign_form_pre' >
                                    <img src={imagePreview.image2} className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div>                                                                        
                    <div className="col-12 mb-3"> 
                        <div className='row'>
                            <div className='col-md-8' >
                                <label htmlFor="basic-url" className="form-label">Image 3</label>
                                <div className="input-group mb-3">
                                    <input type="file" className="form-control" name='image3' id='image3' onChange={(e) => handleImagePreview(e)} />
                                </div>
                            </div>
                            <div className='col-md-4 d-flex justify-content-center'>
                                <div className='campaign_form_pre' >
                                    <img src={imagePreview.image3} className='w-100' />
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div className="col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label">Terms & Conditions</label>
                        <div className="input-group">
                            <input type='file' className="form-control" accept=".pdf" onChange={handleFileChange} />
                        </div>
                        <small className='text-danger mb-3'>upload only pdf!</small>
                    </div>   
                    {/* <div className="col-12 mb-3">
                        <label htmlFor="basic-url" className="form-label">Terms & Conditions</label>
                        <div className="input-group mb-3">
                            <textarea rows={4} {...formik.getFieldProps('terms_conditions')} className="form-control" />
                        </div>
                    </div>    */}
                    <h3 className='my-3'>Registration Form Fields</h3>                                                               
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="nameSwitchDefault">
                                Name
                            </label>
                            <div className='d-flex'>
                                {fieldVal.nameField &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="nameRadioLg" checked={fieldVal.nameFieldRequired} onChange={(e) => setFieldVal({...fieldVal, "nameFieldRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="nameRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="nameSwitchDefault" checked={fieldVal.nameField} onChange={(e) => setFieldVal({...fieldVal, "nameField": e.target.checked})}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="mobileSwitchDefault">
                                Mobile
                            </label>
                            <div className='d-flex'>
                                {fieldVal.mobileField &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="mobileRadioLg" checked={fieldVal.mobileFieldRequired} onChange={(e) => setFieldVal({...fieldVal, "mobileFieldRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="mobileRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="mobileSwitchDefault" checked={fieldVal.mobileField} onChange={(e) => setFieldVal({...fieldVal, "mobileField": e.target.checked})}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="invoiceSwitchDefault">
                                Bill Number
                            </label>
                            <div className='d-flex'>
                                {fieldVal.invoiceField &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="invoiceRadioLg" checked={fieldVal.invoiceFieldRequired} onChange={(e) => setFieldVal({...fieldVal, "invoiceFieldRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="invoiceRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="invoiceSwitchDefault" checked={fieldVal.invoiceField} onChange={(e) => setFieldVal({...fieldVal, "invoiceField": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="vtypeSwitchDefault">
                                Vehicle Type
                            </label>
                            <div className='d-flex'>
                                {fieldVal.vtypeField &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="vtypeRadioLg" checked={fieldVal.vtypeFieldRequired} onChange={(e) => setFieldVal({...fieldVal, "vtypeFieldRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="vtypeRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="vtypeSwitchDefault" checked={fieldVal.vtypeField} onChange={(e) => setFieldVal({...fieldVal, "vtypeField": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="ftypeSwitchDefault">
                                Fuel Type
                            </label>
                            <div className='d-flex'>
                                {fieldVal.ftypeField &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="ftypeRadioLg" checked={fieldVal.ftypeFieldRequired} onChange={(e) => setFieldVal({...fieldVal, "ftypeFieldRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="ftypeRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="ftypeSwitchDefault" checked={fieldVal.ftypeField} onChange={(e) => setFieldVal({...fieldVal, "ftypeField": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="vnumberSwitchDefault">
                                Vehicle Number
                            </label>
                            <div className='d-flex'>
                                {fieldVal.vnumberField &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="vnumberRadioLg" checked={fieldVal.vnumberFieldRequired} onChange={(e) => setFieldVal({...fieldVal, "vnumberFieldRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="vnumberRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="vnumberSwitchDefault" checked={fieldVal.vnumberField} onChange={(e) => setFieldVal({...fieldVal, "vnumberField": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="emailSwitchDefault">
                                Email
                            </label>
                            <div className='d-flex'>
                                {fieldVal.emailField &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="emailRadioLg" checked={fieldVal.emailFieldRequired} onChange={(e) => setFieldVal({...fieldVal, "emailFieldRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="emailRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="emailSwitchDefault" checked={fieldVal.emailField} onChange={(e) => setFieldVal({...fieldVal, "emailField": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="sloganSwitchDefault">
                                Slogan
                            </label>
                            <div className='d-flex'>
                                {fieldVal.sloganField &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="sloganRadioLg" checked={fieldVal.sloganFieldRequired} onChange={(e) => setFieldVal({...fieldVal, "sloganFieldRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="sloganRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="sloganSwitchDefault" checked={fieldVal.sloganField} onChange={(e) => setFieldVal({...fieldVal, "sloganField": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="billAmountSwitchDefault">
                            Bill Amount
                            </label>
                            <div className='d-flex'>
                                {fieldVal.bill_amount &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="billAmountRadioLg" checked={fieldVal.bill_amountRequired} onChange={(e) => setFieldVal({...fieldVal, "bill_amountRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="billAmountRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="billAmountSwitchDefault" checked={fieldVal.bill_amount} onChange={(e) => setFieldVal({...fieldVal, "bill_amount": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="billImageSwitchDefault">
                            Bill Image
                            </label>
                            <div className='d-flex'>
                                {fieldVal.bill_image &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="billImageRadioLg" checked={fieldVal.bill_imageRequired} onChange={(e) => setFieldVal({...fieldVal, "bill_imageRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="billImageRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="billImageSwitchDefault" checked={fieldVal.bill_image} onChange={(e) => setFieldVal({...fieldVal, "bill_image": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="selfiImageSwitchDefault">
                            Selfi Image
                            </label>
                            <div className='d-flex'>
                                {fieldVal.selfi_image &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="selfiImageRadioLg" checked={fieldVal.selfi_imageRequired} onChange={(e) => setFieldVal({...fieldVal, "selfi_imageRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="selfiImageRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="selfiImageSwitchDefault" checked={fieldVal.selfi_image} onChange={(e) => setFieldVal({...fieldVal, "selfi_image": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="attendantSwitchDefault">
                            Customer Attendant Name
                            </label>
                            <div className='d-flex'>
                                {fieldVal.customer_attendant_name &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="attendantRadioLg" checked={fieldVal.customer_attendant_nameRequired} onChange={(e) => setFieldVal({...fieldVal, "customer_attendant_nameRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="attendantRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="attendantSwitchDefault" checked={fieldVal.customer_attendant_name} onChange={(e) => setFieldVal({...fieldVal, "customer_attendant_name": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="attendantCodeSwitchDefault">
                            Customer Attendant Code
                            </label>
                            <div className='d-flex'>
                                {fieldVal.customer_attendant_code &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="attendantCodeRadioLg" checked={fieldVal.customer_attendant_codeRequired} onChange={(e) => setFieldVal({...fieldVal, "customer_attendant_codeRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="attendantCodeRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="attendantCodeSwitchDefault" checked={fieldVal.customer_attendant_code} onChange={(e) => setFieldVal({...fieldVal, "customer_attendant_code": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="officerSwitchDefault">
                            Field Officer Name
                            </label>
                            <div className='d-flex'>
                                {fieldVal.field_officer_name &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="officerRadioLg" checked={fieldVal.field_officer_nameRequired} onChange={(e) => setFieldVal({...fieldVal, "field_officer_nameRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="officerRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="officerSwitchDefault" checked={fieldVal.field_officer_name} onChange={(e) => setFieldVal({...fieldVal, "field_officer_name": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>                                                                    
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="officerCodeSwitchDefault">
                            Field Officer Code
                            </label>
                            <div className='d-flex'>
                                {fieldVal.field_officer_code &&
                                <div className="form-check form-check-custom form-check-solid form-check-sm me-3">
                                    <input className="form-check-input" type="checkbox" id="officerCodeRadioLg" checked={fieldVal.field_officer_codeRequired} onChange={(e) => setFieldVal({...fieldVal, "field_officer_codeRequired": e.target.checked})}/>
                                    <label className="form-check-label" htmlFor="officerCodeRadioLg">
                                        <small>is Required?</small>
                                    </label>
                                </div>}
                                <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                    <input className="form-check-input col-6" type="checkbox" id="officerCodeSwitchDefault" checked={fieldVal.field_officer_code} onChange={(e) => setFieldVal({...fieldVal, "field_officer_code": e.target.checked})}/>
                                </div>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-12 my-4">
                        <div className="d-flex justify-content-between">
                            <label className="" htmlFor="smsSwitchDefault">
                            SMS
                            </label>
                            <div className='form-check form-switch form-check-custom form-check-solid text-end'>
                                <input className="form-check-input col-6" type="checkbox" id="smsSwitchDefault" checked={smsActive} onChange={(e) => setSmsActive(e.target.checked)}/>
                            </div>
                        </div>
                    </div>
                    <h3 className='my-3'>Prize Details</h3>
                    {/* <div className="col-md-6 col-12 my-3">
                        <label htmlFor="basic-url" className="form-label required">Prize Amoung</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select {...formik.getFieldProps('price_amoung')} className="form-control" onChange={(e) => {
                                formik.setFieldValue('price_amoung', e.target.value);
                                if(e.target.value == "campaign_wise") {
                                    let prize:any = {
                                        'campaign_id': editId.id || '',
                                        'campaign_name': editId.name || formik.values.name,
                                        'prize': ''
                                    }
                                    setPrices([prize]);
                                } else if(e.target.value == "area_wise") {
                                    let areaPrizes: any = [];
                                    selectedArea.map((area:any) => areaPrizes.push({
                                        'area_id': area.id,
                                        'area_name': area.city,
                                        'prize': ''
                                    }));
                                    setPrices(areaPrizes);
                                } else {
                                    let roPrizes: any = [];
                                    selectedRo.map((area:any) => roPrizes.push({
                                        'area_id': area.id,
                                        'ro_name': area.ro_name,
                                        'prize': ''
                                    }));
                                    setPrices(roPrizes);
                                }
                            }} >
                                <option value="" >select</option>
                                <option value="campaign_wise" >Campaign Wise</option>
                                <option value="area_wise" >Area Wise</option>
                                <option value="ro_wise" >RO Wise</option>
                            </select>
                        </div>
                    </div> */}
                    <div className="col-md-4 col-12 my-3">
                        <div className='form-check form-check-custom form-check-solid text-end'>
                            <label htmlFor="basic-url" className="form-label me-3">Campaign wise winner</label>
                            <input className="form-check-input col-6" type="checkbox" checked={wiseWinner?.find((item) => item == "campaign_wise") ? true : false} onChange={(e) => {
                                if(e.target.checked) {
                                    setWiseWinner(posr => [...posr, "campaign_wise"]);
                                    formik.setFieldValue('price_amoung', [...wiseWinner, "campaign_wise"]?.join(','));
                                    let prize:any = {
                                        'campaign_id': editId.id || '',
                                        'campaign_name': editId.name || formik.values.name,
                                        'prize': ''
                                    }
                                    setCampaignPrices([prize]);
                                } else {
                                    let eghfwgr = wiseWinner?.filter((data) => data !== "campaign_wise");
                                    setWiseWinner(eghfwgr);
                                    formik.setFieldValue('price_amoung', eghfwgr?.join(','));
                                    setCampaignPrices([]);
                                }
                            }} />
                        </div>
                    </div>
                    <div className="col-md-4 col-12 my-3">
                        <div className='form-check form-check-custom form-check-solid text-end'>
                            <label htmlFor="basic-url" className="form-label me-3">Area wise winner</label>
                            <input className="form-check-input col-6" type="checkbox" checked={wiseWinner?.find((item) => item == "area_wise") ? true : false} onChange={(e) => {
                                if(e.target.checked) {
                                    setWiseWinner(posr => [...posr, "area_wise"]);
                                    formik.setFieldValue('price_amoung', [...wiseWinner, "area_wise"]?.join(','));
                                    let areaPrizes: any = [];
                                    selectedArea.map((area:any) => areaPrizes.push({
                                        'area_id': area.id,
                                        'area_name': area.city,
                                        'prize': ''
                                    }));
                                    setAreaPrices(areaPrizes);
                                } else {
                                    let eghfwgr = wiseWinner?.filter((data) => data !== "area_wise");
                                    setWiseWinner(eghfwgr);
                                    formik.setFieldValue('price_amoung', eghfwgr?.join(','));
                                    setAreaPrices([]);
                                }
                            }}/>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 my-3">
                        <div className='form-check form-check-custom form-check-solid text-end'>
                            <label htmlFor="basic-url" className="form-label me-3">RO wise winner</label>
                            <input className="form-check-input col-6" type="checkbox" checked={wiseWinner?.find((item) => item == "ro_wise") ? true : false} onChange={(e) => {
                                if(e.target.checked) {
                                    setWiseWinner(posr => [...posr, "ro_wise"]);
                                    formik.setFieldValue('price_amoung', [...wiseWinner, "ro_wise"]?.join(','));
                                    let roPrizes: any = [];
                                    selectedRo.map((area:any) => roPrizes.push({
                                        'ro_id': area.id,
                                        'ro_name': area.ro_name,
                                        'prize': ''
                                    }));
                                    setRoPrices(roPrizes);
                                } else {
                                    let eghfwgr = wiseWinner?.filter((data) => data !== "ro_wise");
                                    setWiseWinner(eghfwgr);
                                    formik.setFieldValue('price_amoung', eghfwgr?.join(','));
                                    setRoPrices([]);
                                }
                            }}/>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 my-3">
                        <label htmlFor="basic-url" className="form-label required">Campaign Winner Type</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select className="form-control" value={winnerType?.campaign_winner} onChange={(e) => {
                                setWinnerType({...winnerType, campaign_winner: e.target.value});
                            }} >
                                <option value="" >select</option>
                                <option value="static" >Static</option>
                                <option value="dynamic" >Dynamic</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 my-3">
                        <label htmlFor="basic-url" className="form-label required">Area Winner Type</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select className="form-control" value={winnerType?.area_winner} onChange={(e) => {
                                setWinnerType({...winnerType, area_winner: e.target.value});
                            }} >
                                <option value="" >select</option>
                                <option value="static" >Static</option>
                                <option value="dynamic" >Dynamic</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4 col-12 my-3">
                        <label htmlFor="basic-url" className="form-label required">RO Winner Type</label>
                        <div className="input-group first mb-3 input_prepend">
                            <select className="form-control" value={winnerType?.ro_winner} onChange={(e) => {
                                setWinnerType({...winnerType, ro_winner: e.target.value});
                            }} >
                                <option value="" >select</option>
                                <option value="static" >Static</option>
                                <option value="dynamic" >Dynamic</option>
                            </select>
                        </div>
                    </div>
                </div>
                {editId && editId.id != undefined && campaignPrices?.length > 0 && <>
                <h3 className='mb-3'>Campaign Prizes</h3>
                {campaignPrices?.map((data:any, i:any) => {
                    return(                
                        <div className="col-12 my-4" key={i}>
                        <div className="d-flex justify-content-between flex-wrap">
                            <label className="d-flex align-items-center mb-3" htmlFor={"priceDetails"+i} >
                            {data.campaign_name}
                            </label>
                            {/* <div className='input-group first mb-3 mw-300px'>
                                <input className="form-control" type="text" id={"priceDetails"+i} value={data.prize} onChange={(e) => {
                                    setPrices(prevData => {
                                    const updatedData = [...prevData];
                                    const objectToUpdate = updatedData[i];                                        
                                    if (objectToUpdate) {
                                        objectToUpdate.prize = e.target.value;
                                    }                                        
                                    return updatedData;
                                    })
                                }}/>
                            </div> */}
                            <div className="input-group first mb-3 input_prepend">
                                <Select
                                    isMulti
                                    options={prizesList}
                                    closeMenuOnSelect={false}
                                    components={makeAnimated()}
                                    getOptionLabel={(option:any) => option.name}
                                    getOptionValue={(option:any) => option.id}
                                    value={prizesList?.filter((item:any) => data.prize?.split(',').indexOf(item.id?.toString()) !== -1)}
                                    classNamePrefix=""
                                    className={"w-100 "}
                                    onChange={(val:any) => {  
                                        // setSelected(val);
                                        setCampaignPrices(prevData => {
                                            const updatedData = [...prevData];
                                            const objectToUpdate = updatedData[i];                                        
                                            if (objectToUpdate) {
                                                objectToUpdate.prize = val.map((item: { id: any; }) => item.id)?.join(',');
                                                objectToUpdate.campaign_id = editId.id;
                                                objectToUpdate.campaign_name = editId.name;
                                            }                                        
                                            return updatedData;
                                            })
                                    }}
                                    placeholder={"select.."}
                                />
                            </div>
                        </div>
                    </div>
                )})}</>}
                {editId && editId.id != undefined && areaPrices?.length > 0 && <>
                <h3 className='mb-3'>Area Prizes</h3>
                {areaPrices?.map((data:any, i:any) => {
                    return(                
                        <div className="col-12 my-4" key={i}>
                        <div className="d-flex justify-content-between flex-wrap">
                            <label className="d-flex align-items-center mb-3" htmlFor={"priceDetails"+i} >
                            {data.area_name}
                            </label>
                            {/* <div className='input-group first mb-3 mw-300px'>
                                <input className="form-control" type="text" id={"priceDetails"+i} value={data.prize} onChange={(e) => {
                                    setPrices(prevData => {
                                    const updatedData = [...prevData];
                                    const objectToUpdate = updatedData[i];                                        
                                    if (objectToUpdate) {
                                        objectToUpdate.prize = e.target.value;
                                    }                                        
                                    return updatedData;
                                    })
                                }}/>
                            </div> */}
                            <div className="input-group first mb-3 input_prepend">
                                <Select
                                    isMulti
                                    options={prizesList}
                                    closeMenuOnSelect={false}
                                    components={makeAnimated()}
                                    getOptionLabel={(option:any) => option.name}
                                    getOptionValue={(option:any) => option.id}
                                    value={prizesList?.filter((item:any) => data.prize?.split(',').indexOf(item.id?.toString()) !== -1)}
                                    classNamePrefix=""
                                    className={"w-100 "}
                                    onChange={(val:any) => {  
                                        // setSelected(val);
                                        setAreaPrices(prevData => {
                                            const updatedData = [...prevData];
                                            const objectToUpdate = updatedData[i];                                        
                                            if (objectToUpdate) {
                                                objectToUpdate.prize = val.map((item: { id: any; }) => item.id)?.join(',');
                                            }                                        
                                            return updatedData;
                                            })
                                    }}
                                    placeholder={"select.."}
                                />
                            </div>
                        </div>
                    </div>
                )})}</>}
                {editId && editId.id != undefined && roPrices?.length > 0 && <>
                <h3 className='mb-3'>RO Prizes</h3>
                {roPrices?.map((data:any, i:any) => {
                    return(                
                        <div className="col-12 my-4" key={i}>
                        <div className="d-flex justify-content-between flex-wrap">
                            <label className="d-flex align-items-center mb-3" htmlFor={"priceDetails"+i} >
                            {data.ro_name}
                            </label>
                            {/* <div className='input-group first mb-3 mw-300px'>
                                <input className="form-control" type="text" id={"priceDetails"+i} value={data.prize} onChange={(e) => {
                                    setPrices(prevData => {
                                    const updatedData = [...prevData];
                                    const objectToUpdate = updatedData[i];                                        
                                    if (objectToUpdate) {
                                        objectToUpdate.prize = e.target.value;
                                    }                                        
                                    return updatedData;
                                    })
                                }}/>
                            </div> */}
                            <div className="input-group first mb-3 input_prepend">
                                <Select
                                    isMulti
                                    options={prizesList}
                                    closeMenuOnSelect={false}
                                    components={makeAnimated()}
                                    getOptionLabel={(option:any) => option.name}
                                    getOptionValue={(option:any) => option.id}
                                    value={prizesList?.filter((item:any) => data.prize?.split(',').indexOf(item.id?.toString()) !== -1)}
                                    classNamePrefix=""
                                    className={"w-100 "}
                                    onChange={(val:any) => {  
                                        // setSelected(val);
                                        setRoPrices(prevData => {
                                            const updatedData = [...prevData];
                                            const objectToUpdate = updatedData[i];                                        
                                            if (objectToUpdate) {
                                                objectToUpdate.prize = val.map((item: { id: any; }) => item.id)?.join(',');
                                            }                                        
                                            return updatedData;
                                            })
                                    }}
                                    placeholder={"select.."}
                                />
                            </div>
                        </div>
                    </div>
                )})}</>}
                <span className='d-flex justify-content-end'>
                    <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal' onClick={() => {
                        formik.resetForm();
                        imgRemove();
                        setFieldVal({
                            "sloganField": false,
                            "emailField": false,
                            "vnumberField": false,
                            "ftypeField": false,
                            "vtypeField": false,
                            "invoiceField": false,
                            "mobileField": false,
                            "nameField": false,
                            "bill_amount":false,
                            "bill_image":false,
                            "selfi_image":false,
                            "customer_attendant_name":false,
                            "customer_attendant_code":false,
                            "field_officer_name":false,
                            "field_officer_code":false,
                            "sloganFieldRequired": false,
                            "emailFieldRequired": false,
                            "vnumberFieldRequired": false,
                            "ftypeFieldRequired": false,
                            "vtypeFieldRequired": false,
                            "invoiceFieldRequired": false,
                            "mobileFieldRequired": false,
                            "nameFieldRequired": false,
                            "bill_amountRequired":false,
                            "bill_imageRequired":false,
                            "selfi_imageRequired":false,
                            "customer_attendant_nameRequired":false,
                            "customer_attendant_codeRequired":false,
                            "field_officer_nameRequired":false,
                            "field_officer_codeRequired":false,
                        });
                        setDeleteId('');
                        setSearch('');
                        setEditId({});
                    }}>Discard</button>
                    <button type='submit' className='btn btn-sm btn-primary'>Save</button>
                </span>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className='modal fade' id='atom_Campaign_delete_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>Confirmation</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_Campaign_delete_modal_close'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10'>
            <p>Are you sure want to delete?</p>
            <span className='d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>No</button>
                <button type='submit' className='btn btn-sm btn-primary' onClick={() => handleDelete()}>Yes</button>
            </span>
          </div>
        </div>
      </div>
    </div> 
    <div className='modal fade' id='atom_Campaign_winner_list_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content mh-600px'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>{winnerList.name}</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_Campaign_winner_list_modal_close'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10'>
            {/* <p>Are you sure want to delete?</p>
            <span className='d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>No</button>
                <button type='submit' className='btn btn-sm btn-primary' onClick={() => handleDelete()}>Yes</button>
            </span> */}
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>sl.no</th>
                        <th>{winnerList.prize_wise == 'area_wise' ? 'Area' : 'RO'}</th>
                        <th>Link</th>
                    </tr>
                </thead>
                {winnerList.prize_wise == 'area_wise' ?
                <tbody>
                    {areas?.filter((item) => winnerList.area?.split(',').some(item2 => item2?.toString() === item.id?.toString()))?.map((data:any, i) => {
                    return(
                        <tr key={i}>
                            <td>{i+1+first}</td>
                            <td>{data.city}</td>
                            <td>
                                <button type='button' className='btn btn-sm btn-success me-2' title='Area wise winner' onClick={() => {
                                    navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_URL}winnerPage?cid=${winnerList.id}&rid=&aid=${data.id}`);
                                    toast.success("Copied!", {
                                        position: "bottom-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }} >
                                    copy link!
                                </button>
                            </td>
                        </tr>
                    )})}                    
                </tbody> : 
                <tbody>
                    {ro?.filter((item) => winnerList.ro_id?.split(',').some(item2 => item2?.toString() === item.id?.toString()))?.map((data:any, i) => {
                    return(
                        <tr key={i}>
                            <td>{i+1+first}</td>
                            <td>{data.ro_name}</td>
                            <td>
                                <button type='button' className='btn btn-sm btn-success me-2' title='RO wise winner' onClick={() => {
                                    navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_URL}winnerPage?cid=${winnerList.id}&rid=${data.id}&aid=&wise=ro_winner`);
                                    toast.success("Copied!", {
                                        position: "bottom-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }} >
                                    copy link!
                                </button>
                            </td>
                        </tr>
                    )})}                    
                </tbody>}
            </table>
          </div>
        </div>
      </div>
    </div> 
    <div className='modal fade' id='atom_Campaign_winner_list_area_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content mh-600px'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>{winnerList.name}</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_Campaign_winner_list_modal_close'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10'>
            {/* <p>Are you sure want to delete?</p>
            <span className='d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>No</button>
                <button type='submit' className='btn btn-sm btn-primary' onClick={() => handleDelete()}>Yes</button>
            </span> */}
            <table className='table table-bordered'>
                <thead>
                    <tr>
                        <th>sl.no</th>
                        <th>Area</th>
                        <th>Link</th>
                    </tr>
                </thead>
                <tbody>
                    {areas?.filter((item) => winnerList.area?.split(',').some(item2 => item2?.toString() === item.id?.toString()))?.map((data:any, i) => {
                    return(
                        <tr key={i}>
                            <td>{i+1+first}</td>
                            <td>{data.city}</td>
                            <td>
                                <button type='button' className='btn btn-sm btn-success me-2' title='Area wise winner' onClick={() => {
                                    navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_URL}winnerPage?cid=${winnerList.id}&rid=&aid=${data.id}&wise=area_winner`);
                                    toast.success("Copied!", {
                                        position: "bottom-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }} >
                                    copy link!
                                </button>
                            </td>
                        </tr>
                    )})}                    
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> 
    {loading ?
    <div className='w-100 h-100'>
        <div className='d-flex justify-content-center flex-column align-items-center h-100'>
            <div className="spinner-border taskloader" role="status">                                    
                <span className="sr-only">Loading...</span>
            </div>
        </div> 
    </div> :   
    <div className='card'>
        <div className='card-header d-flex align-items-center mobile-padding'>
            <h3>Campaign</h3>
            <span className='d-flex'>
                <span className='position-relative me-3'>
                <input onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                className='form-control form-control-sm form-control-solid mw-200px'
                placeholder='Search..'
                /><i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i></span>
                {permissions?.save == 1 ? 
                <Link to={'form'} className='btn btn-sm btn-primary me-2 text-nowrap' title='(Alt+a)'>
                    <i className="bi bi-plus-lg"></i> Add Campaign
                </Link>
                : ""}
                {/* <button className='btn btn-sm btn-primary me-2 text-nowrap' data-bs-toggle='modal' data-bs-target='#atom_Campaign_add_modal' onClick={() => {
                    formik.resetForm();
                    imgRemove();
                    setDeleteId('');
                    setSearch('');
                    setEditId({});
                    setSelectedState([]);
                    setSelectedDivision([]);
                    setSelectedArea([]);
                    setSelectedRo([]);
                    setSelectedVehicle([]);
                    setSelectedFuel([]);
                    setCampaignPrices([{
                        'campaign_id': '',
                        'campaign_name': '',
                        'prize': ''
                    }]);
                    setWiseWinner(["campaign_wise"]);
                }}>
                    <i className="bi bi-plus-lg"></i> Add Campaign
                </button> */}
            </span>
        </div>
        <div className='card-body pt-0'>
            <div className=' overflow-x-auto'>
                <table
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' >
                    <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th>Sl.No</th>
                            <th className='p-0' style={{minWidth: '200px'}}>
                                <div className='d-flex align-items-center'>
                                    <select className='form-select border-0 text-muted fw-bolder fs-7 text-uppercase gs-0 mb-2' id='ewrwkergiuwgerywf' onChange={(e) => setOMCId(e.target.value)}>
                                        <option value="" >OMC</option>
                                        {omc.map((item, i) => {
                                            return(
                                                <option value={item.id} key={i} >{item.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </th>
                            <th style={{minWidth: '200px'}} onClick={() => setSort(sort == 0 ? 1 : sort == 1 ? 2 : 0)}>
                                <div className='d-flex justify-content-between flex-nowrap'>
                                    <span>Campaign</span>
                                    {sort == 1 &&
                                    <i className="bi bi-caret-up"></i>}
                                    {sort == 2 &&
                                    <i className="bi bi-caret-down"></i>}
                                </div>
                            </th>
                            <th style={{minWidth: '200px'}}>States</th>
                            <th style={{minWidth: '200px'}}>Divisions</th>
                            <th style={{minWidth: '200px'}}>Areas</th>
                            {/* <th style={{minWidth: '200px'}}>RO Name</th> */}
                            <th className='text-center'>Actions</th>
                            <th className='text-center'>Winner</th>
                        </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                        {campaign?.length > 0 ? pageData.map((item, i) => {
                        return(
                        <tr key={i}>
                            <td>{i+1+first}</td>
                            <td className='py-1'>
                                <div className='d-flex align-items-center'>
                                    <div className='prof_img_pre_list border'>
                                    {item.omc_logo ? 
                                        <img src={process.env.REACT_APP_API_URL+'/uploads/company/logo/'+item.omc_id+'/'+item.omc_logo} className='profile_image w-100'/> : 
                                        <p className='h-100 d-flex justify-content-center align-items-center bg-gray-300'>LOGO</p>}
                                    </div>
                                    <div className='d-flex flex-column ms-3'>
                                        <span>{item.omc_name}</span>
                                        <small className='text-nowrap'># {item.omc_shortcode}</small>
                                    </div>
                                </div>
                            </td>
                            <td className='py-1'>
                                <div className='d-flex align-items-center'>
                                    <div className='d-flex flex-column'>
                                        <span>{item.name}</span>
                                        <small>{moment(item.start_date).format('DD/MM/YY')} - {moment(item.end_date).format('DD/MM/YY')}</small>
                                    </div>
                                </div>
                            </td>
                            <td title={item.state_name?.replace(/,/g, ', ')}><span className='two-line-limit'>{item.state_name?.replace(/,/g, ', ')}</span></td>
                            <td title={item.division_name?.replace(/,/g, ', ')}><span className='two-line-limit'>{item.division_name?.replace(/,/g, ', ')}</span></td>
                            <td title={item.area_name?.replace(/,/g, ', ')}><span className='two-line-limit'>{item.area_name?.replace(/,/g, ', ')}</span></td>
                            {/* <td title={item.ro_name?.replace(/,/g, ', ')}><span className='two-line-limit'>{item.ro_name?.replace(/,/g, ', ')}</span></td> */}
                            <td className='text-center text-nowrap'>     
                            {permissions?.edit == 1 ? 
                                <Link to={`form?cid=${item.id}`} className='btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2'
                                >
                                    <i className="bi bi-pencil-fill"></i>
                                </Link>
                                : '' }
                                {/* <button className='btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2' onClick={() => {
                                    setEditId(item);
                                    }}>
                                    <i className="bi bi-pencil-fill"></i>
                                </button> */}
                                {permissions?.delete == 1 ? 
                                <button className='btn btn-sm btn-secondary btn-active-color-danger btn-icon me-2' data-bs-toggle='modal' data-bs-target='#atom_Campaign_delete_modal' onClick={() => setDeleteId(item.id)}>
                                    <i className="bi bi-trash"></i>
                                </button>
                                : '' }
                                <Link to={"registrationList?cid="+item.id} className='btn btn-sm btn-secondary btn-active-color-info btn-icon me-2' title='Registration List'>
                                    <i className="bi bi-list"></i>
                                </Link>
                                <Link to={"campaignRoList?cid="+item.id} className='btn btn-sm btn-secondary btn-active-color-warn btn-icon me-2' title='more'>
                                    <i className="bi bi-three-dots-vertical"></i>
                                </Link>
                                <Link to={'customPrice?cid='+item.id} className='btn btn-sm btn-primary me-2'>Custom Prizes - {item?.custom_count}</Link>
                            </td>
                            <td className='text-center text-nowrap'>
                                {/* {moment(item.end_date).add(1, 'days') < moment() ?  */}
                                <>
                                {item.prize_wise?.split(',').find((item) => item == "campaign_wise") &&
                                <button type='button' className='btn btn-sm btn-success me-2' title='Campaign wise winner' onClick={() => {
                                    navigator.clipboard.writeText(`${process.env.REACT_APP_FRONT_URL}winnerPage?cid=${item.id}&rid=&aid=&wise=campaign_winner`);
                                    toast.success("Copied!", {
                                        position: "bottom-center",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                        progress: undefined,
                                        theme: "light",
                                    });
                                }} >
                                    copy link!
                                </button>} 
                                {item.prize_wise?.split(',').find((item) => item == "area_wise") && 
                                <button type='button' className='btn btn-sm btn-success me-2' data-bs-toggle='modal' data-bs-target='#atom_Campaign_winner_list_area_modal' onClick={() => {
                                    setWinnerList(item);
                                }} >Area Wise Winner!</button>}
                                {item.prize_wise?.split(',').find((item) => item == "ro_wise") && 
                                <button type='button' className='btn btn-sm btn-success me-2' data-bs-toggle='modal' data-bs-target='#atom_Campaign_winner_list_modal' onClick={() => {
                                    setWinnerList(item);
                                }} >RO Wise Winner!</button>} 
                                </> 
                                {/* : 
                                <p className='text-secondary text-wrap disabled me-2'>
                                    Campaign not completed yet!
                                </p>} */}
                            </td>
                        </tr>)}) : 
                        <tr>
                            <td colSpan={8}>
                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                    No matching records found
                                </div>
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
            <div className='table_paginator mt-3 d-flex justify-content-end'>
                <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={campaign?.length}
                    rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
        {/* <PDFViewer /> */}
    </div>}
    </>)
}



export default Campaign;
