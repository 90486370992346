import react, {useState, useEffect} from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { toast } from 'react-toastify';
import { PageTitle } from '../../../_metronic/layout/core/PageData';
import { Paginator } from 'primereact/paginator';
import { campaignGetById, deleteCustomWinner, getCustomWinnerList, getRoDropdown } from './core/_requests';
import moment from 'moment';
import { getAreaOffice } from '../OMC/core/_request';
import { usePDF, Margin } from 'react-to-pdf';

const WinnersList = () => {

    const [states, setStates] = useState<any[]>([]);
    const [pageData, setPageData] = useState<any[]>([]);
    const [customPrice, setCustomPrice] = useState<any>({});
    const [statesForSearch, setStatesForSearch] = useState<any[]>([]);
    const [deleteId, setDeleteId] = useState('');
    const [areas, setAreas] = useState<any[]>([]);
    const [ro, setRo] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [winnerWise, setWinnerWise] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const queryParams = new URLSearchParams(window.location.search);
    const campaignId:any = queryParams.get('cid');
    const customId:any = queryParams.get('cpid');
    const customNum:any = queryParams.get('cpi');
    console.log('statesstatesstates',states);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const areaList = async () => {
        const response = await getAreaOffice();
        setAreas(response.output);
    }

    const RoList = async () => {
        const response = await getRoDropdown();
        setRo(response.output);        
    }
    console.log("ewthritgeoirutgoeirt", customNum);

    const statesList = async () => {
        setLoading(true);
        const response = await getCustomWinnerList(campaignId, customId, '', '');
        setStates(response.output);         
        setStatesForSearch(response.output);         
        setLoading(false);
    }

    const handleDelete = async () => {
        const response = await deleteCustomWinner(campaignId, customId, deleteId)
        if(response.status == 200) {
            document.getElementById('dfkjertgutbekrtorthelriteriy')?.click();
            statesList();
            toast.success("Deleted Successfully!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    function tableToCSV() {
 
        var csv_data:any = [];
    
        var rows = document.getElementsByClassName('trow');
        for (var i = 0; i < rows.length; i++) {
    
            var cols:any = rows[i]?.querySelectorAll('td,th');
    
            var csvrow:any = [];
            for (var j = 0; j < cols.length; j++) {
                csvrow?.push(cols[j]?.innerHTML);
            }
    
            csv_data?.push(csvrow?.join(","));
        }
    
        csv_data = csv_data?.join('\n');
    
        downloadCSVFile(csv_data);
    }
    
    function downloadCSVFile(csv_data) {
     
        let CSVFile = new Blob([csv_data], {
            type: "text/csv"
        });
    
        var temp_link = document.createElement('a');
    
        temp_link.download = `${customNum+' (' + states[0]?.campaign_name + ')'}.csv`;
        var url = window.URL.createObjectURL(CSVFile);
        temp_link.href = url;
    
        temp_link.style.display = "none";
        document.body.appendChild(temp_link);
    
        temp_link.click();
        document.body.removeChild(temp_link);
    }

    const { toPDF, targetRef } = usePDF({
        filename: `${customNum+' (' + states[0]?.campaign_name + ')'}.pdf`,
        page: { orientation: 'landscape', margin: Margin.MEDIUM }
      });

    useEffect(() => {
        areaList();
        RoList();        
    }, []);

    useEffect(() => {
        if(search.length > 0) {
            const states = statesForSearch.filter((p) => p.customer_name?.toLowerCase().includes(search) || p.prize_name?.toLowerCase().includes(search) || p.wise?.replace('_', ' ')?.toLowerCase().includes(search) || p.email?.toLowerCase().includes(search) || p.mobile?.toLowerCase().includes(search) || p.campaign_name?.toLowerCase().includes(search) || p.ro_name?.toLowerCase().includes(search) || p.area_name?.toLowerCase().includes(search));
            console.log("foghiuehtoeirhtpeoirth", states);
            setStates(states);
        } else {
            setStates(statesForSearch);
        }
    }, [search]);

    useEffect(() => {
        if(campaignId) {
            statesList();
        }
    }, [campaignId]);

    useEffect(() => {        
        let page = states?.slice(first, first+rows);
        setPageData(page);
    }, [first, rows, states]);

  return (<>
  <PageTitle>Manage Campaign</PageTitle>
    <div className='modal fade' id='atom_cwinner_delete_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>Confirmation</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='dfkjertgutbekrtorthelriteriy'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10'>
            <p>Are you sure want to delete?</p>
            <span className='d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>No</button>
                <button type='submit' className='btn btn-sm btn-primary' onClick={() => handleDelete()}>Yes</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    {loading ?
    <div className='w-100 h-100'>
        <div className='d-flex justify-content-center flex-column align-items-center h-100'>
            <div className="spinner-border taskloader" role="status">                                    
                <span className="sr-only">Loading...</span>
            </div>
        </div> 
    </div> :
    <div className='card'>
        <div className='card-header d-flex align-items-center'>
            <h3>{customNum} <small>{' (' + states[0]?.campaign_name + ')'}</small></h3>
            <span className='d-flex'>
                <span className='position-relative me-3'>
                <input onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                className='form-control form-control-sm form-control-solid mw-200px'
                placeholder='Search..'
                /><i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i></span>
                <div className='btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2' id="dropdownMenu2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className="bi bi-cloud-download"></i>
                </div>
                <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
                    <button className="dropdown-item" type="button" onClick={() => toPDF()}>PDF</button>
                    <button className="dropdown-item" type="button" onClick={tableToCSV}>CSV</button>
                </div>
            </span>
        </div>
        <div className='card-body pt-0'>
            <div className='overflow-x-auto'>
                <table ref={targetRef}
                    className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' >
                    <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th>sl.no</th>
                            <th className='text-center' style={{minWidth: '200px'}}>Name</th>
                            <th className='text-center' style={{minWidth: '300px'}}>Prize</th>
                            <th className='text-center' style={{minWidth: '250px'}}>Wise</th>
                            <th className='text-center' style={{minWidth: '100px'}}>Phone</th>
                            <th className='text-center' style={{minWidth: '200px'}}>Email</th>
                            <th className='text-center'>Delete</th>
                        </tr>
                    </thead>
                    <tbody className='text-gray-600 fw-bold'>
                    {states.length > 0 ? pageData?.map((data:any, i) => {
                        console.log('dataNameDivisionName', data);
                        
                        return(
                            <tr key={i}>
                                <td>{i+1+first}</td>
                                <td className='text-center' >{data.customer_name}</td>
                                <td className='py-1'>
                                    <div className='d-flex align-items-center'>
                                        <div className='prof_img_pre_list border'>
                                        {data.prize_image ? 
                                            <img src={process.env.REACT_APP_API_URL+'/uploads/masters/image/'+data.prize+'/'+data.prize_image} className='profile_image w-100'/> : 
                                            <p className='h-100 d-flex justify-content-center align-items-center bg-gray-300'>prize</p>}
                                        </div>
                                        <div className='d-flex flex-column ms-3'>
                                            <span>{data.prize_name}</span>
                                        </div>
                                    </div>
                                </td>
                                <td className='py-1 text-wrap text-center'>{data.wise == "area_winner" ? 'Area Wise' : data.wise == "division_winner" ? 'Division Wise' : data.wise == "ro_winner" ? 'RO Wise' : 'Campaign Wise'} {`(${data.wise == "area_winner" ? data.area_name : data.wise == "division_winner" ? data.division_name : data.wise == "ro_winner" ? data.ro_name : data.campaign_name})`}</td>
                                <td className='text-center' >{data.mobile}</td>
                                <td className='text-center' >{data.email}</td>
                                <td className='text-center' >
                                    <button className='btn btn-sm btn-secondary btn-active-color-danger btn-icon me-2' data-bs-toggle='modal' data-bs-target='#atom_cwinner_delete_modal' onClick={async () => {
                                        setDeleteId(data.id)
                                    }}>
                                        <i className="bi bi-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        )}) : 
                        <tr>
                            <td colSpan={7}>
                            <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                No matching records found
                            </div>
                            </td>
                        </tr>} 
                    </tbody>
                </table>
            </div>
            <div className='table_paginator mt-3 d-flex justify-content-end'>
                <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={states.length}
                    rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
        <div className='d-none' >
            <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' >
                <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 trow'>
                        <th>sl.no</th>
                        <th>Name</th>
                        <th>Prize</th>
                        <th>Wise</th>
                        <th>Phone</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                {states.length > 0 ? states?.map((data:any, i) => {
                    return(
                        <tr key={i} className='trow'>
                            <td>{i+1+first}</td>
                            <td>{data.customer_name?.replace(/,/g, '')}</td>
                            <td>{data.prize_name?.replace(/,/g, '')}</td>
                            <td>{data.wise == "area_winner" ? 'Area Wise' : data.wise == "ro_winner"  ? 'RO Wise' : data.wise == "division_winner"  ? 'Division Wise'  : 'Campaign Wise'} {`(${data.wise == "area_winner" ? data.area_name?.replace(/,/g, '') : data.wise == "ro_winner" ? data.ro_name?.replace(/,/g, '') : data.wise == "division_winner" ? data.division_name?.replace(/,/g, '') : data.campaign_name?.replace(/,/g, '')})`}</td>
                            <td>{data.mobile?.replace(/,/g, '')}</td>
                            <td>{data.email?.replace(/,/g, '')}</td>
                        </tr>
                    )}) : 
                    <tr>
                        <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                        </div>
                        </td>
                    </tr>} 
                </tbody>
            </table>
        </div>
    </div>}
    </>)
}

export default WinnersList;
