import react, { useEffect, useRef, useState } from 'react';
import Confetti from "react-confetti";
import { RandomReveal } from "react-random-reveal";
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { getCampaignResults, getCampaignResultsRefresh } from './core/_requests';
import { getPrize } from '../BussinesSettings/core/_requests';

const Winner = () => {
    const [height, setHeight] = useState<any>(null);
    const [width, setWidth] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [confeti, setConfeti] = useState<any>(false);
    const [start, setStart] = useState<any>(false);
    const [cup, setCup] = useState<any>(false);
    const [email, setEmail] = useState<any>(false);
    const [vehicle, setVehicle] = useState<any>(false);
    const [fuel, setFuel] = useState<any>(false);
    const [state, setState] = useState<any>(false);
    const [area, setArea] = useState<any>(false);
    const [ro, setRo] = useState<any>(false);
    const [attendant, setAttendant] = useState<any>(false);
    const [officer, setOfficer] = useState<any>(false);
    const [refreshBtn, setRefreshBtn] = useState<any>(false);
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [winner, setWinner] = useState<any>({});
    const [prize, setprize] = useState<any[]>([]);
    const [givenprize, setGivenprize] = useState<any[]>([]);
    const confetiRef:any = useRef(null);
    const queryParams = new URLSearchParams(window.location.search);
    const campaignId = queryParams.get('cid');
    const areaId = queryParams.get('aid');
    const roId = queryParams.get('rid');
    const wise:any = queryParams.get('wise');

    const prizeList = async () => {
        const response = await getPrize();
        setprize(response.output);
    }

    const campaignWinner = async () => {
        setLoading(true);
        const response = await getCampaignResults(campaignId, roId, areaId);
        if(response.output?.length > 0) {
        setWinner(response.output[0]);
        let jgefegr = JSON.parse(response.output[0]?.winner_type)
        if(jgefegr[wise] == 'static') {
        setRefreshBtn(true);
        }
        let kjrg = prize?.filter((val:any) => response.output[0]?.prize_id?.split(',')?.indexOf(val.id?.toString()) !== -1)
        setGivenprize(kjrg);
        setLoading(false);
        setStart(true);
        setTimeout(() => setConfeti(true), 5500);
        setTimeout(() => setCup(true), 5500);
        setTimeout(() => setEmail(true), 6500);
        setTimeout(() => setVehicle(true), 6700);
        setTimeout(() => setFuel(true), 6900);
        setTimeout(() => setState(true), 7100);
        setTimeout(() => setArea(true), 7300);
        setTimeout(() => setRo(true), 7500);
        setTimeout(() => setAttendant(true), 7700);
        setTimeout(() => setOfficer(true), 7900);
        } else {
            setErrorMessage('There is no Registrations!')
        }
    }
  
    useEffect(() => {
        prizeList();
        setHeight(confetiRef.current?.clientHeight);
        setWidth(confetiRef.current?.clientWidth);
    }, []);
    
    return( 
        <div className='campaign-bg h-100'>
        <div className='container h-100'>
            {errorMessage == '' ?
            <div className="confettie-wrap h-100" ref={confetiRef}>
                {!start ?
                <div className='h-100 d-flex align-items-center justify-content-center'>
                    <button type='button' className='btn btn-primary btn-height-width' onClick={() => {
                            campaignWinner();
                        }} >
                            {loading ? <div className="spinner-border taskloader" role="status">                                    
                                <span className="sr-only">Please Wait...</span>
                            </div> : "Start Winner Selection"}</button>
                </div> :
                <div className='h-100 d-flex align-items-center justify-content-center'>
                    {confeti && <Confetti numberOfPieces={150} />}
                    <p className='h-100 winner_name_anime d-flex flex-column align-items-center justify-content-center'>
                        {cup && <span className='cup_animation d-flex flex-nowrap image-scroll' >
                            {givenprize?.map((item, i) => {
                                return(
                                    <img src={item.image ? process.env.REACT_APP_API_URL+'/uploads/masters/image/'+item.id+'/'+item.image : toAbsoluteUrl('/media/winner_page_image-removebg-preview.png')} className='mw-200px anime-img' key={i} />
                                )
                            })}
                        </span>}
                        <RandomReveal
                        isPlaying
                        duration={5}
                        updateInterval={0.1}
                        revealDuration={0.5}
                        characterSet={[
                            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
                            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'
                        ]}
                        characters={winner?.customer_name}
                        onComplete={() => ({ shouldRepeat: false, delay: 3 })}
                        />
                        {email && <p className='fs-6 cup_animation'>Email / Phone : <small className='fs-9 text-gray-500'>{winner?.email}</small> / <small className='fs-9 text-gray-500'>{winner?.customer_mobile}</small></p>}
                        {vehicle && <p className='fs-6 cup_animation'>Vehicle Number / Type : <small className='fs-9'>{winner?.vehicle_number}</small> / <small className='fs-9'>{winner?.vehicle_type}</small></p>}
                        {fuel && <p className='fs-6 cup_animation'>Fuel Type : <small className='fs-9'>{winner?.fuel_type}</small></p>}
                        {state && <p className='fs-6 cup_animation'>State : <small className='fs-9'>{winner?.state_name}</small></p>}
                        {area && <p className='fs-6 cup_animation'>Area : <small className='fs-9'>{winner?.area_name}</small></p>}
                        {ro && <p className='fs-6 cup_animation'>RO : <small className='fs-9'>{winner?.ro_name}</small> - <small className='fs-9'># {winner?.sap_code}</small></p>}
                        {attendant && <p className='fs-6 cup_animation'>Customer Attendant : <small className='fs-9'>{winner?.customer_attendant_name}</small> - <small className='fs-9'>{winner?.customer_attendant_code}</small></p>}
                        {officer && <p className='fs-6 cup_animation'>Field Officer : <small className='fs-9'>{winner?.field_officer_name}</small> - <small className='fs-9'>{winner?.field_officer_code}</small></p>}
                        {officer && refreshBtn && <button type='button' className='btn btn-primary' onClick={async() => {
                            setLoading(true);
                            setConfeti(false);
                            setCup(false);
                            setEmail(false);
                            setVehicle(false);
                            setFuel(false);
                            setState(false);
                            setArea(false);
                            setRo(false);
                            setAttendant(false);
                            setOfficer(false);
                            const response = await getCampaignResultsRefresh(campaignId, roId, areaId, "dynamic");
                            if(response.output?.length > 0) {
                            setWinner(response.output[0]);
                            let jgefegr = JSON.parse(response.output[0]?.winner_type)
                            if(jgefegr[wise] == 'static') {
                            setRefreshBtn(true);
                            }
                            let kjrg = prize?.filter((val:any) => response.output[0]?.prize_id?.split(',')?.indexOf(val.id?.toString()) !== -1)
                            setGivenprize(kjrg);
                            setLoading(false);
                            setStart(true);
                            setTimeout(() => setConfeti(true), 5500);
                            setTimeout(() => setCup(true), 5500);
                            setTimeout(() => setEmail(true), 6500);
                            setTimeout(() => setVehicle(true), 6700);
                            setTimeout(() => setFuel(true), 6900);
                            setTimeout(() => setState(true), 7100);
                            setTimeout(() => setArea(true), 7300);
                            setTimeout(() => setRo(true), 7500);
                            setTimeout(() => setAttendant(true), 7700);
                            setTimeout(() => setOfficer(true), 7900);
                            } else {
                                setErrorMessage('There is no Registrations!')
                            }
                        }} >Refresh winner</button>}
                    </p>
                </div>}
            </div> :
            <div className='h-100 d-flex align-items-center justify-content-center'>
                <h2>{errorMessage}</h2>
            </div>}

            <div className="footer footer-fixed py-4">
                <div className='d-sm-block d-none'>
                    <div className="container-fluid d-flex align-items-center justify-content-center">
                        <div className="text-dark order-2 order-md-1">
                            <span className="text-muted fw-bold me-2">2023 ©</span>
                            <a href="#" className="text-gray-800 text-hover-primary">The Atom | <small className='text-gray-500'>Technical Partner</small> @ Vriksha Techno Solutions Pvt Ltd</a>
                        </div>
                    </div>
                </div>

                <div className='d-sm-none d-block'>
                    <div className="container-fluid d-flex flex-column align-items-center justify-content-center">
                        <div className="text-dark">
                            <span className="text-muted fw-bold me-2">2023 ©</span>
                            <a href="#" className="text-gray-800 text-hover-primary">The Atom </a>
                        </div>
                        <div className="text-dark">
                            <a href="#" className="text-dark"><small className='text-gray-500'>Technical Partner</small> @ Vriksha Techno Solutions Pvt Ltd</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
            
        </div>
    )
}

export default Winner;