import react, { useEffect, useRef, useState } from 'react';
import Confetti from "react-confetti";
import { RandomReveal } from "react-random-reveal";
import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { getCampaignResults, getCampaignResultsRefresh, getCustomResults, getCustomResultsRefresh } from './core/_requests';
import { getPrize } from '../BussinesSettings/core/_requests';

const CustomWinner = () => {
    const [height, setHeight] = useState<any>(null);
    const [width, setWidth] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [confeti, setConfeti] = useState<any>(false);
    const [start, setStart] = useState<any>(false);
    const [cup, setCup] = useState<any>(false);
    const [email, setEmail] = useState<any>(false);
    const [vehicle, setVehicle] = useState<any>(false);
    const [fuel, setFuel] = useState<any>(false);
    const [prize1, setPrize1] = useState<any>(false);
    const [state, setState] = useState<any>(false);
    const [area, setArea] = useState<any>(false);
    const [division, setDivision] = useState<any>(false);
    const [ro, setRo] = useState<any>(false);
    const [attendant, setAttendant] = useState<any>(false);
    const [officer, setOfficer] = useState<any>(false);
    const [refreshBtn, setRefreshBtn] = useState<any>(false);
    const [errorMessage, setErrorMessage] = useState<any>('');
    const [winner, setWinner] = useState<any[]>([]);
    const [prize, setprize] = useState<any[]>([]);
    const [givenprize, setGivenprize] = useState<any[]>([]);
    const confetiRef:any = useRef(null);
    const queryParams = new URLSearchParams(window.location.search);
    const campaignId = queryParams.get('cid');
    const areaId = queryParams.get('aid');
    const divisionId = queryParams.get('did');
    const roId = queryParams.get('rid');
    const customPrize = queryParams.get('cpid');
    const fromDate = queryParams.get('fdate');
    const toDate = queryParams.get('tdate');
    const wise:any = queryParams.get('wise');

    const prizeList = async () => {
        const response = await getPrize();
        setprize(response.output);
    }

    const campaignWinner = async () => {
        setLoading(true);
        const response = await getCustomResults(campaignId, areaId, divisionId, roId, customPrize, fromDate, toDate, wise);
        console.log('response.message', response.message);
        if(response.output?.length > 0) {
        setWinner(response.output);
        
        let jgefegr = JSON.parse(response.output[0]?.winner_type)
        if(jgefegr[wise] == 'static') {
        setRefreshBtn(true);
        }
        // let kjrg = prize?.filter((val:any) => response.output[0]?.prize_id?.split(',')?.indexOf(val.id?.toString()) !== -1)
        // setGivenprize(kjrg);
        setLoading(false);
        setStart(true);
        setTimeout(() => setConfeti(true), 5500);
        setTimeout(() => setCup(true), 5500);
        setTimeout(() => setEmail(true), 6500);
        setTimeout(() => setVehicle(true), 6700);
        setTimeout(() => setFuel(true), 6900);
        setTimeout(() => setState(true), 7100);
        setTimeout(() => setArea(true), 7300);
        setTimeout(() => setDivision(true), 7300);
        setTimeout(() => setRo(true), 7500);
        setTimeout(() => setAttendant(true), 7700);
        setTimeout(() => setOfficer(true), 7900);
        setTimeout(() => setPrize1(true), 6900);
        }
        else if (response.message == "Campaign not completed yet") {
            setErrorMessage('Campaign not completed yet!')
        }
        else if (response.message == "There is no prize selected") {
            setErrorMessage('There is no prize selected!')
        }
        else {
            setErrorMessage('There is no Registrations!')
        }
    }
  
    useEffect(() => {
        prizeList();
        setHeight(confetiRef.current?.clientHeight);
        setWidth(confetiRef.current?.clientWidth);
    }, []);
    
    return(
        <div className='campaign-bg h-100'>

            <div className='h-100'>
                {errorMessage == '' ?
                <div className="confettie-wrap h-100" ref={confetiRef}>
                    {!start ?
                    <div className='h-100 d-flex align-items-center justify-content-center'>
                        <button type='button' className='btn btn-primary btn-height-width' onClick={() => {
                                campaignWinner();
                            }} >
                                {loading ? <div className="spinner-border taskloader" role="status">                                    
                                    <span className="sr-only">Please Wait...</span>
                                </div> : "Start Winner Selection"}</button>
                    </div> :
                    <div className='h-100 d-flex justify-content-center overflow-y-auto'>
                        {confeti && <Confetti numberOfPieces={500} />}
                        <div className='' >
                            {winner.map((value, l) => {
                                setTimeout(() => {
                                    console.log(`Processing item ${l + 1} with delay...`);
                                  }, 1000 * l);
                                return(
                                <div className='mb-18 mt-10' key={l}>
                                    <div className={`winner_name_anime d-flex flex-column align-items-center justify-content-center winner-card`}>
                                    {cup && <span className='cup_animation d-flex flex-nowrap image-scroll' >
                                        {prize?.map((item, i) => {
                                            if(item.id == value?.prize_id) {
                                            return(
                                                <img src={item.image ? process.env.REACT_APP_API_URL+'/uploads/masters/image/'+item.id+'/'+item.image : toAbsoluteUrl('/media/winner_page_image-removebg-preview.png')} className='mw-200px anime-img' key={i} />
                                            )}
                                        })}
                                    </span>}
                                    <RandomReveal
                                    isPlaying
                                    duration={5}
                                    updateInterval={0.1}
                                    revealDuration={0.5}
                                    characterSet={[
                                        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 
                                        // '1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '!', '@', '#', '$', '₹', '%', '^', '&', '*', '(', ')', '-', '_', '=', '+', '`', '~', '{', '}', '[', ']', '|', ':', ';', '"', "'", '.', ',', '<', '>', '?', '/', ' '
                                    ]}
                                    characters={value?.customer_name}
                                    onComplete={() => ({ shouldRepeat: false, delay: 3 })}
                                    />
                                    {email && <p className='fs-6 cup_animation'>Email / Phone : <small className='fs-12 text-dark-500'>{value?.email}</small> / <small className='fs-12 text-dark-500'>{value?.customer_mobile}</small></p>}
                                    {vehicle && <p className='fs-6 cup_animation'>Vehicle Number / Type : <small className='fs-12'>{value?.vehicle_number}</small> / <small className='fs-12'>{value?.vehicle_type}</small></p>}
                                    {fuel && <p className='fs-6 cup_animation'>Fuel Type : <small className='fs-12'>{value?.fuel_type}</small></p>}
                                    {prize1 && <p className='fs-6 cup_animation'>Prize Name : <small className='fs-9'>{value?.prize_name}</small></p>}
                                    {state && <p className='fs-6 cup_animation'>State : <small className='fs-12'>{value?.state_name}</small></p>}
                                    {division && <p className='fs-6 cup_animation'>Division : <small className='fs-12'>{value?.division_name}</small></p>}
                                    {area && <p className='fs-6 cup_animation'>Area : <small className='fs-12'>{value?.area_name}</small></p>}
                                    {ro && <p className='fs-6 cup_animation'>RO : <small className='fs-12'>{value?.ro_name}</small> - <small className='fs-12'># {value?.sap_code}</small></p>}
                                    {attendant && <p className='fs-6 cup_animation'>Customer Attendant : <small className='fs-12'>{value?.customer_attendant_name}</small> - <small className='fs-12'>{value?.customer_attendant_code}</small></p>}
                                    {officer && <p className='fs-6 cup_animation'>Field Officer : <small className='fs-12'>{value?.field_officer_name}</small> - <small className='fs-12'>{value?.field_officer_code}</small></p>}
                                    {officer && refreshBtn && <button type='button' className='btn btn-primary' onClick={async() => {
                                        setWinner([]);
                                        setLoading(true);
                                        setConfeti(false);  
                                        setCup(false);
                                        setEmail(false);
                                        setVehicle(false);
                                        setFuel(false);
                                        setState(false);
                                        setArea(false);
                                        setDivision(false);
                                        setRo(false);
                                        setAttendant(false);
                                        setOfficer(false);
                                        setPrize1(false);
                                        const response = await getCustomResultsRefresh(campaignId, areaId, divisionId, roId, customPrize, fromDate, toDate, wise, "dynamic");
                                        if(response.status == 200) {
                                        setWinner(response.output);
                                        let jgefegr = JSON.parse(response.output[0]?.winner_type);
                                        if(jgefegr[wise] == 'static') {
                                        setRefreshBtn(true);
                                        }
                                        // let kjrg = prize?.filter((val:any) => response.output[0]?.prize_id?.split(',')?.indexOf(val.id?.toString()) !== -1)
                                        // setGivenprize(kjrg);
                                        setLoading(false);
                                        setStart(true);
                                        setTimeout(() => setConfeti(true), 5500);
                                        setTimeout(() => setCup(true), 5500);
                                        setTimeout(() => setEmail(true), 6500);
                                        setTimeout(() => setVehicle(true), 6700);
                                        setTimeout(() => setFuel(true), 6900);
                                        setTimeout(() => setState(true), 7100);
                                        setTimeout(() => setArea(true), 7300);
                                        setTimeout(() => setDivision(true), 7300);
                                        setTimeout(() => setRo(true), 7500);
                                        setTimeout(() => setAttendant(true), 7700);
                                        setTimeout(() => setOfficer(true), 7900);
                                        setTimeout(() => setPrize1(true), 6900);
                                        } else {
                                            setErrorMessage('There is no Registrations!')
                                        }
                                    }} >Refresh winner</button>}
                                </div>
                                </div>
                                )
                            })} 
                        </div>
                    </div>}
                </div> :
                <div className='h-100 d-flex align-items-center justify-content-center'>
                    <h2>{errorMessage}</h2>
                </div>}
            </div>
        </div>
    )
}

export default CustomWinner;