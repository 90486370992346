import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL

export const DASHBOARD_COUNT_URL = `${API_URL}/dashboard_count`
export const WINNER_LIST_URL = `${API_URL}/dashboard_winner_list`


export function getDashboardCounts() {
    return axios.get(DASHBOARD_COUNT_URL)
    .then(res => res.data)
}

export function getWinnersCampaign() {
    return axios.get(WINNER_LIST_URL)
    .then(res => res.data)
}