import react, {useState, useEffect} from 'react';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';
import { deleteAreas, getAreas, getDivisions, getStates, saveAreas, updateAreas } from './core/_requests';
import { toast } from 'react-toastify';
import { PageTitle } from '../../../_metronic/layout/core/PageData';
import { Paginator } from "primereact/paginator";

const Area = () => {

    const [area, setAreas] = useState<any[]>([]);
    const [pageData, setPageData] = useState<any[]>([]);
    const [states, setStates] = useState<any[]>([]);
    const [divisions, setDivisions] = useState<any[]>([]);
    const [areaForSearch, setAreasForSearch] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [editId, setEditId] = useState('');
    const [search, setSearch] = useState('');
    const [stateId, setstateId] = useState('');
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(event.rows);
    };

    const areaList = async () => {
        setLoading(true);
        const response = await getAreas();
        setAreas(response.output);
        setAreasForSearch(response.output);
        setLoading(false);
    }

    const statesList = async () => {
        const response = await getStates();
        setStates(response.output);
    }
    
    const divisionList = async () => {
        const response = await getDivisions();
        setDivisions(response.output);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        let areaName = (document.getElementById('area_name') as HTMLInputElement)?.value;
        let stateName = (document.getElementById('area_state_name') as HTMLInputElement)?.value;
        let divisionName = (document.getElementById('area_division_name') as HTMLInputElement)?.value;
        if(areaName.length > 0 && stateName && divisionName) {
        const response = await saveAreas({name: areaName, state_id: stateName, division_id: divisionName})
        if(response.status == 200) {
            (document.getElementById('area_name') as HTMLInputElement).value = "";
            (document.getElementById('area_state_name') as HTMLInputElement).value = "";
            (document.getElementById('area_division_name') as HTMLInputElement).value = "";
            document.getElementById('atom_area_add_modal_close')?.click();
            areaList();
            toast.success(response.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(response.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }} else {
            toast.warn('Please fill all the fields..', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 
        }
    }

    const handleDelete = async () => {
        const response = await deleteAreas(deleteId)
        if(response.status == 200) {
            setDeleteId('');
            document.getElementById('atom_area_delete_modal_close')?.click();
            areaList();
            toast.success(response.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        } else {
            toast.error(response.message, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const handleUpdate = async (id) => {
        let areaName = (document.getElementById('area_name_update'+id) as HTMLInputElement).value;
        let areaStateName = (document.getElementById('area_state_name_update'+id) as HTMLInputElement).value;
        let areaDivisionName = (document.getElementById('area_division_name_update'+id) as HTMLInputElement).value;
        if(areaName.length > 0 && areaStateName && areaDivisionName) {
            const response = await updateAreas(id, {name: areaName, state_id: areaStateName, division_id: areaDivisionName})
            if(response.status == 200) {
                setEditId('');
                areaList();
                toast.success(response.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            } else {
                setEditId('');
                toast.error(response.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            toast.warn('Please Enter Area name..', {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            }); 
        }    
    }

    useEffect(() => {
        if(search.length > 0) {
            const area = areaForSearch.filter((p) => p.name?.toLowerCase().includes(search) || p.state_name?.toLowerCase().includes(search) || p.division_name?.toLowerCase().includes(search));
            setAreas(area);
        } else {
            setAreas(areaForSearch);
        }
    }, [search]);

    useEffect(() => {
        areaList();
        statesList();
        divisionList();
    }, []);

    useEffect(() => {        
        let page = area?.slice(first, first+rows);
        setPageData(page);
    }, [first, rows, area]);

  return (<>
  <PageTitle>Business Settings</PageTitle>
    <div className='modal fade' id='atom_area_add_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>Add Area</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_area_add_modal_close'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10 pt-5'>
            <form onSubmit={handleSubmit}>
                <select className='form-select form-select-solid mb-8' id="area_state_name" onChange={(e) => setstateId(e.target.value)}>
                    <option value="" >select State</option>
                    {states.map((item, i) => {
                        return(
                            <option value={item.id} key={i} >{item.name}</option>
                        )
                    })}
                </select>
                {stateId &&
                <select className='form-select form-select-solid mb-8' id="area_division_name">
                    <option value="" >select Division</option>
                    {divisions.map((item, i) => {
                        if(stateId == item.state_id) {
                        return(
                            <option value={item.id} key={i} >{item.name}</option>
                        )
                    }})}
                </select>}
                <input name='area_name' id="area_name"
                className='form-control form-control-solid mb-8'
                placeholder='Enter Area Name..'
                />
                <span className='d-flex justify-content-end'>
                    <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>Cancel</button>
                    <button type='submit' className='btn btn-sm btn-primary'>Save</button>
                </span>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div className='modal fade' id='atom_area_delete_modal' aria-hidden='true'>
      <div className='modal-dialog mw-550px'>
        <div className='modal-content'>
          <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
            <div className='text-center'>
              <h3 className='mb-3'>Confirmation</h3>
            </div>
            <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_area_delete_modal_close'>
              <KTIcon iconName='cross' className='fs-1' />
            </div>
          </div>
          <div className='modal-body scroll-y pb-10'>
            <p>Are you sure want to delete?</p>
            <span className='d-flex justify-content-end'>
                <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal'>No</button>
                <button type='submit' className='btn btn-sm btn-primary' onClick={() => handleDelete()}>Yes</button>
            </span>
          </div>
        </div>
      </div>
    </div>
    {loading ?
    <div className='w-100 h-100'>
        <div className='d-flex justify-content-center flex-column align-items-center h-100'>
            <div className="spinner-border taskloader" role="status">                                    
                <span className="sr-only">Loading...</span>
            </div>
        </div> 
    </div> :
    <div className='card'>
        <div className='card-header d-flex align-items-center'>
            <h3>Areas</h3>
            <span className='d-flex'>
                <span className='position-relative me-3'>
                <input onChange={(e) => setSearch(e.target.value?.toLowerCase())}
                className='form-control form-control-sm form-control-solid mw-200px'
                placeholder='Search..'
                /><i className="bi bi-search fs-3 me-3 position-absolute top-25 end-0"></i></span>
                <button className='btn btn-sm btn-primary me-2 text-nowrap' data-bs-toggle='modal' data-bs-target='#atom_area_add_modal' onClick={() => {
                    setDeleteId('');
                    setSearch('');
                    setEditId('');
                    setstateId('');
                    (document.getElementById('area_name') as HTMLInputElement).value = "";
                    (document.getElementById('area_state_name') as HTMLInputElement).value = "";
                    (document.getElementById('area_division_name') as HTMLInputElement).value = "";
                }}>
                    <i className="bi bi-plus-lg"></i> Add Area
                </button>
            </span>
        </div>
        <div className='card-body pt-0'>
            <table
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer' >
                <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                        <th>Sl.No</th>
                        <th>State</th>
                        <th>Division</th>
                        <th>Area</th>
                        <th className='text-center'>Actions</th>
                    </tr>
                </thead>
                <tbody className='text-gray-600 fw-bold'>
                    {area.length > 0 ? pageData.map((item, i) => {
                    return(
                    <tr key={i}>
                        <td>{i+1+first}</td>
                        <td>
                        {editId == item.id ? 
                            <select className='form-select form-select-solid' defaultValue={item.state_id} id={"area_state_name_update"+item.id} onChange={(e) => setstateId(e.target.value)}>
                                {states.map((sta, i) => {
                                    return(
                                        <option value={sta.id} key={i} >{sta.name}</option>
                                    )
                                })}
                            </select> : <span>{item.state_name}</span>}
                        </td>
                        <td>
                        {editId == item.id ? 
                            <select className='form-select form-select-solid' defaultValue={item.division_id} id={"area_division_name_update"+item.id}>
                                {divisions.map((div, i) => {
                                    if(stateId && stateId == div.state_id) {
                                    return(
                                        <option value={div.id} key={i} >{div.name}</option>
                                    )} else if(stateId == '' && item.state_id == div.state_id) {
                                        return(
                                            <option value={div.id} key={i} >{div.name}</option>
                                        )  
                                    }
                                })}
                            </select> : <span>{item.division_name}</span>}
                        </td>
                        <td>
                        {editId == item.id ? 
                            <input name='area_name_update' defaultValue={item.name} id={"area_name_update"+item.id}
                            className='form-control form-control-solid'
                            placeholder='Enter Area Name..'
                            /> : <span>{item.name}</span>}
                        </td>
                        <td className='text-center'>
                            {editId == item.id ? <>
                            <button className='btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2' onClick={() => handleUpdate(item.id)}>
                                <i className="bi bi-check-lg fs-3"></i>
                            </button>
                            <button className='btn btn-sm btn-secondary btn-active-color-danger btn-icon me-2' onClick={() => setEditId('')}>
                                <i className="bi bi-x-lg fs-4"></i>
                            </button></> : <>
                            <button className='btn btn-sm btn-secondary btn-active-color-primary btn-icon me-2' onClick={() => {
                                setEditId(item.id);
                                setstateId('');
                                }}>
                                <i className="bi bi-pencil-fill"></i>
                            </button>
                            <button className='btn btn-sm btn-secondary btn-active-color-danger btn-icon' data-bs-toggle='modal' data-bs-target='#atom_area_delete_modal' onClick={() => setDeleteId(item.id)}>
                                <i className="bi bi-trash"></i>
                            </button></>}
                        </td>
                    </tr>)}) : 
                    <tr>
                        <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                        </div>
                        </td>
                    </tr>}
                </tbody>
            </table>
            <div className='table_paginator mt-3 d-flex justify-content-end'>
                <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={area.length}
                    rowsPerPageOptions={[10, 50, 100, 500, 1000]}
                    onPageChange={onPageChange}
                />
            </div>
        </div>
    </div>}
    </>)
}

export default Area;
