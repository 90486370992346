import react, {useState, useEffect} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { getCampaignPoster, registerCampaign, saveCampaign } from './core/_requests';
import { toast } from 'react-toastify';
import { getMaster } from '../BussinesSettings/core/_requests';
import { KTIcon, toAbsoluteUrl } from '../../../_metronic/helpers';

const Campaigns = (props) => {
    // const {poster} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [trId, setTrId] = useState('');
    const [vehicleType, setVehicleType] = useState<any[]>([]);
    const [fuelType, setFuelType] = useState<any[]>([]);
    const [poster, setPoster] = useState<any>({});
    const [posterForm, setPosterForm] = useState<any>({});
    const [imgPre, setImgPre] = useState(false);
    const [imagePreview, setImagePreview] = useState<any>(null);
    const [imageFile, setImageFile] = useState<any>(null);
    const [selfiImgPre, setSelfiImgPre] = useState(false);
    const [selfiImagePreview, setSelfiImagePreview] = useState<any>(null);
    const [selfiImageFile, setSelfiImageFile] = useState<any>(null);
    const queryParams = new URLSearchParams(window.location.search);
    const campaignId = queryParams.get('cid');
    const roId = queryParams.get('rid');

    const vehicleTypeList = async () => {
        const response = await getMaster('vehicle_type');
        setVehicleType(response.output);
    }

    const fuelTypeList = async () => {
        const response = await getMaster('fuel_type');
        setFuelType(response.output);
    }

    const roCampaignList = async () => {
        setLoading(true);
        const response = await getCampaignPoster(campaignId, roId);

        console.log('Total Response', response);
        console.log('Total poster ', poster);
        console.log('posterForm', posterForm);
        console.log('campaignId', campaignId);
        console.log('roId', roId);
        setPoster(response.output[0]);
        setPosterForm(response.form_fields);
        setLoading(false);
    }

    const initialValues = {
        "ro_id": '',
        "name": '',
        "mobile": '',
        "invoice_no": '',
        "vehicle_type": '',
        "fuel_type": '',
        "vehicle_number": '',
        "email": '',
        "accept": '',
        "slogan": '',
        "officerCode": '',
        "fieldOfficer": '',
        "attendantCode": '',
        "attendantName": '',
        "billImage": '',
        "selfiImage": '',
        "billAmount": '',
        "initial_label1": '',
        "initial_label2": '',
        "initial_label3": '',
        "initial_label4": '',
        "initial_label5": '',
    }

    const campaignSchema = Yup.object().shape({
        name: posterForm?.nameField && posterForm?.nameFieldRequired ? Yup.string().required('Name is required') : Yup.string(),
        mobile: posterForm?.mobileField && posterForm?.mobileFieldRequired ? Yup.string().required('Mobile Number is required').min(10, "Mobile number must be 10 characters") : Yup.string().min(10, "Mobile number must be 10 characters"),
        invoice_no: posterForm?.invoiceField && posterForm?.invoiceFieldRequired ? Yup.string().required('Invoice Number is required') : Yup.string(),
        vehicle_type: posterForm?.vtypeField && posterForm?.vtypeFieldRequired ? Yup.string().required('Vehicle type is required') : Yup.string(),
        fuel_type: posterForm?.ftypeField && posterForm?.ftypeFieldRequired ? Yup.string().required('Fuel type is required') : Yup.string(),
        vehicle_number: posterForm?.vnumberField && posterForm?.vnumberFieldRequired ? Yup.string().required('Vehicle Number is required') : Yup.string(),
        email: posterForm?.emailField && posterForm?.emailFieldRequired ? Yup.string().required('Email is required') : Yup.string(),
        slogan: posterForm?.sloganField && posterForm?.sloganFieldRequired ? Yup.string().required('Slogan is required') : Yup.string(),
        officerCode: posterForm?.field_officer_code && posterForm?.field_officer_codeRequired ? Yup.string().required('Field Officer Code is required') : Yup.string(),
        fieldOfficer: posterForm?.field_officer_name && posterForm?.field_officer_nameRequired ? Yup.string().required('Field Officer is required') : Yup.string(),
        attendantCode: posterForm?.customer_attendant_code && posterForm?.customer_attendant_codeRequired ? Yup.string().required('Customer Attendant Code is required') : Yup.string(),
        attendantName: posterForm?.customer_attendant_name && posterForm?.customer_attendant_nameRequired ? Yup.string().required('Customer Attendant is required') : Yup.string(),
        billImage: posterForm?.bill_image && posterForm?.bill_imageRequired ? Yup.string().required('Bill Image is required') : Yup.string(),
        selfiImage: posterForm?.selfi_image && posterForm?.selfi_imageRequired ? Yup.string().required('Selfie Image is required') : Yup.string(),
        billAmount: posterForm?.bill_amount && posterForm?.bill_amountRequired ? Yup.string().required('Bill Amount is required') : Yup.string(),
        initial_label1: posterForm?.initial_label1 && posterForm?.initial_label1Required ? Yup.string().required(' * '+posterForm?.initial_label1 + ' is required') : Yup.string(),
        initial_label2: posterForm?.initial_label1 && posterForm?.initial_label2Required ? Yup.string().required(' * '+posterForm?.initial_label2 + ' is required') : Yup.string(),
        initial_label3: posterForm?.initial_label1 && posterForm?.initial_label3Required ? Yup.string().required(' * '+posterForm?.initial_label3 + ' is required') : Yup.string(),
        initial_label4: posterForm?.initial_label1 && posterForm?.initial_label4Required ? Yup.string().required(' * '+posterForm?.initial_label4 + ' is required') : Yup.string(),
        initial_label5: posterForm?.initial_label1 && posterForm?.initial_label5Required ? Yup.string().required(' * '+posterForm?.initial_label5 + ' is required') : Yup.string(),
    })

    const formik = useFormik({
        initialValues,
        validationSchema: campaignSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          try {
            const formData = new FormData();

                formData.append("ro_id", roId!)
                formData.append("campaign_id", campaignId!)
                formData.append("name", values.name)
                formData.append("mobile", values.mobile)
                formData.append("invoice_no", values.invoice_no)
                formData.append("vehicle_type", values.vehicle_type)
                formData.append("fuel_type", values.fuel_type)
                formData.append("vehicle_number", values.vehicle_number)
                formData.append("email", values.email)
                formData.append("accept", values.accept)
                formData.append("slogan", values.slogan)
                formData.append("field_officer_code", values.officerCode)
                formData.append("field_officer_name", values.fieldOfficer)
                formData.append("initial_label1", values.initial_label1)
                formData.append("initial_label2", values.initial_label2)
                formData.append("initial_label3", values.initial_label3)
                formData.append("initial_label4", values.initial_label4)
                formData.append("initial_label5", values.initial_label5)
                formData.append("customer_attendant_code", values.attendantCode)
                formData.append("customer_attendant_name", values.attendantName)
                formData.append("bill_image", imageFile!)
                formData.append("selfi_image", selfiImageFile!)
                formData.append("bill_amount", values.billAmount)

            const response = await registerCampaign(formData)   

            if(response.status == 200) {
                setLoading(false);
                setTrId(response.output?.id)
                resetForm();
                setSuccess(true);
                document.getElementById('atom_Campaign_poster_modal')?.click();
                toast.success(response.message, {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // (document.getElementById('lfkhwiuhiwuehriwueyrwuefbhufsuaygi') as HTMLInputElement).checked = false;
            }
          } catch (error) {
            console.error(error)
            toast.error("Somethig went wrong, Please try after sometimes!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            setStatus('Server Error')
            setSubmitting(false)
            setLoading(false)
          }
        },
    })

    const isValidFileUploaded=(file:any)=>{
        const validExtensions = ['png','jpeg','jpg']
        const fileExtension = file.type.split('/')[1]
        return validExtensions.includes(fileExtension)
      }

    const handleImagePreview = (e:any) => {
        if(e.target.files[0].size > 10485760){
            toast.error("Image size should be below 10MB!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            (document.getElementById('billImg') as HTMLInputElement).value = '';
            return;
        } else {
            const file = e.target.files[0];
            if(isValidFileUploaded(file)){
                let image_as_base64:any = URL.createObjectURL(e.target.files[0]);
                let image_as_files:any = e.target.files[0];            
                setImageFile(image_as_files);
                setImagePreview(image_as_base64);
                setImgPre(true);
                formik.setFieldValue('billImage', image_as_files)
           } else { 
            (document.getElementById('billImg') as HTMLInputElement).value = '';
            toast.error("Image should be .jpg, .jpeg and .png only!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
           }  
        }
    }

    const handleSelfiImagePreview = (e:any) => {
        if(e.target.files[0].size > 10485760){
            toast.error("Image size should be below 10MB!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            (document.getElementById('selfiImg') as HTMLInputElement).value = '';
            return;
        } else {
            const file = e.target.files[0];
            if(isValidFileUploaded(file)){
                let image_as_base64:any = URL.createObjectURL(e.target.files[0]);
                let image_as_files:any = e.target.files[0];            
                setSelfiImageFile(image_as_files);
                setSelfiImagePreview(image_as_base64);
                setSelfiImgPre(true);
                formik.setFieldValue('selfiImage', image_as_files)
           } else { 
            (document.getElementById('selfiImg') as HTMLInputElement).value = '';
            toast.error("Image should be .jpg, .jpeg and .png only!", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
           }  
        }
    }

    useEffect(() => {
        roCampaignList();
        vehicleTypeList();
        fuelTypeList();
    }, []);

    return(<>    
        <div className='modal fade' id='atom_terms_conditions_modal' aria-hidden='true'>
        <div className='modal-dialog mw-550px'>
            <div className='modal-content'>
            <div className='modal-header border-0 pb-0 justify-content-between align-items-center'>
                <div className='text-center'>
                <h3 className='mb-3'>Terms & Conditions</h3>
                </div>
                <div className='btn btn-sm btn-icon btn-active-color-primary' data-bs-dismiss='modal' id='atom_terms_conditions_modal_close'>
                <KTIcon iconName='cross' className='fs-1' />
                </div>
            </div>
            <div className='modal-body scroll-y pb-10 mh-500px'>
                {poster?.terms_conditions}
            </div>
            </div>
        </div>
        </div>
        {loading ?
        <div className='w-100 h-100'>
            <div className='d-flex justify-content-center flex-column align-items-center h-100'>
                <div className="spinner-border taskloader" role="status">                                    
                    <span className="sr-only">Loading...</span>
                </div>
            </div> 
        </div> : 
        poster && poster.id != undefined ?
        <div className='d-flex justify-content-center'>
            {!success ? <>            
            <div className='p-7 poster_container copy-margin'>
                <div className='top-section mb-5'>
                    <div className='row' >
                        <div className='col-6'>
                            <div className='d-flex'>
                                <div className='poster_logo'>
                                    {poster?.omc_logo && <img src={process.env.REACT_APP_API_URL+'/uploads/company/logo/'+poster?.omc_id+'/'+poster?.omc_logo} className='w-100' />}
                                </div>
                                {poster?.omc_logo && poster?.image1 && <div className="vertical-line mx-2"></div>}
                                <div className='poster_image1'>
                                    {poster?.image1 && <img src={process.env.REACT_APP_API_URL+'/uploads/campaign/image1/'+poster?.id+'/'+poster?.image1} className='w-100' />}
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className='d-flex flex-end flex-column'>
                                {poster?.image2 && 
                                <div className='poster_image2'>
                                    <img src={process.env.REACT_APP_API_URL+'/uploads/campaign/image2/'+poster?.id+'/'+poster?.image2} className='w-100' />
                                </div>}
                                {poster?.image1 && 
                                <div className='poster_image3'>
                                    <img src={process.env.REACT_APP_API_URL+'/uploads/campaign/image3/'+poster?.id+'/'+poster?.image3} className='w-100' />
                                </div>}
                            </div>
                        </div>
                    </div>                
                </div>
                {poster?.banner &&
                <div className='banner-section' >
                    <div className='row'>
                        <div className='campaign_banner card p-5'>
                            <img src={process.env.REACT_APP_API_URL+'/uploads/campaign/banner/'+poster?.id+'/'+poster?.banner} className='w-100 h-100' />
                        </div>
                    </div>
                </div>}
                <div className='form-section' >
                    <div className='row'>
                        <div className='p-5 card registration-shadow'>

                            <div  className='col-12' >
                                <div className='w-100 d-flex justify-content-center my-5' >
                                    <h2>REGISTRATION</h2>
                                </div>
                                <div className=''>
                                    <form noValidate onSubmit={formik.handleSubmit}>
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6 pb-0'>{poster.ro_name_translation || 'RO Name'}</label>
                                                <span>{poster?.sap_code} - {poster?.ro_name}</span>
                                            <div className='col-lg-8 fv-row'>
                                            </div>
                                        </div>
                                        {posterForm.nameField &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.nameFieldRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Name</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter your name..'
                                                {...formik.getFieldProps('name')}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.name}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.mobileField &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.mobileFieldRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Mobile</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                type='text' maxLength={10}
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Mobile number..'
                                                {...formik.getFieldProps('mobile')} 
                                                onChange={(e) => formik.setFieldValue("mobile", e.target?.value.replace(/[^0-9]/g, ""))}
                                                />
                                                {formik.touched.mobile && formik.errors.mobile && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.mobile}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.invoiceField && 
                                        <div className='row mb-6'>
                                            <label className={posterForm.invoiceFieldRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Bill Number</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Bill number..'
                                                {...formik.getFieldProps('invoice_no')}
                                                />
                                                {formik.touched.invoice_no && formik.errors.invoice_no && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.invoice_no}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.vtypeField &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.vtypeFieldRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Vehicle Type</label>
                                            <div className='col-lg-8 fv-row'>
                                                <select className='form-select form-select-lg form-select-solid border' 
                                                {...formik.getFieldProps('vehicle_type')} >
                                                    <option value="" >select vehicle</option>
                                                    {vehicleType?.filter((item:any) => poster.vehicle_type?.split(',').indexOf(item.id?.toString()) !== -1)?.map((vet, i) => {
                                                        return(
                                                            <option value={vet.id} key={i} >{vet.option_value}</option>
                                                        )
                                                    })}
                                                </select>
                                                {formik.touched.vehicle_type && formik.errors.vehicle_type && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.vehicle_type}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.ftypeField &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.ftypeFieldRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Fuel Type</label>
                                            <div className='col-lg-8 fv-row'>
                                                <select className='form-select form-select-lg form-select-solid border' 
                                                {...formik.getFieldProps('fuel_type')}>
                                                    <option value="" >select fuel</option>
                                                    {fuelType?.filter((item:any) => poster.fuel_type?.split(',').indexOf(item.id?.toString()) !== -1)?.map((fue, i) => {
                                                        return(
                                                            <option value={fue.id} key={i} >{fue.option_value}</option>
                                                        )
                                                    })}
                                                </select>
                                                {formik.touched.fuel_type && formik.errors.fuel_type && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.fuel_type}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.vnumberField &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.vnumberFieldRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Vehicle Number</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Vehicle Number..'
                                                {...formik.getFieldProps('vehicle_number')}
                                                />
                                                {formik.touched.vehicle_number && formik.errors.vehicle_number && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.vehicle_number}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.emailField &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.emailFieldRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Email</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Email..'
                                                {...formik.getFieldProps('email')}
                                                />
                                                {formik.touched.email && formik.errors.email && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.email}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.bill_amount &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.bill_amountRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Bill Amount</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='number'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Bill Amount..'
                                                {...formik.getFieldProps('billAmount')}
                                                />
                                                {formik.touched.billAmount && formik.errors.billAmount && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.billAmount}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.bill_image &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.bill_imageRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Bill Image</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='file' id="billImg"
                                                className='form-control form-control-lg form-control-solid border'
                                                onChange={handleImagePreview}
                                                />
                                                {formik.touched.billImage && formik.errors.billImage && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.billImage}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.selfi_image &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.selfi_imageRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Selfi Image</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='file' id="selfiImg"
                                                className='form-control form-control-lg form-control-solid border'
                                                onChange={handleSelfiImagePreview}
                                                />
                                                {formik.touched.selfiImage && formik.errors.selfiImage && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.selfiImage}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.customer_attendant_name &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.customer_attendant_nameRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Customer Attendant Name</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Customer Attendant Name..'
                                                {...formik.getFieldProps('attendantName')}
                                                />
                                                {formik.touched.attendantName && formik.errors.attendantName && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.attendantName}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.customer_attendant_code &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.customer_attendant_codeRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Customer Attendant Code</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Customer Attendant Code..'
                                                {...formik.getFieldProps('attendantCode')}
                                                />
                                                {formik.touched.attendantCode && formik.errors.attendantCode && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.attendantCode}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.field_officer_name &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.field_officer_nameRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Field Officer Name</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Field Officer Name..'
                                                {...formik.getFieldProps('fieldOfficer')}
                                                />
                                                {formik.touched.fieldOfficer && formik.errors.fieldOfficer && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.fieldOfficer}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {/* POSTER 1 */}
                                        {posterForm.initial_label_icon1 &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.initial_label1Required ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>{posterForm?.initial_label1 == '' ? 'No Name' : posterForm?.initial_label1 }</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder={posterForm?.initial_label1 == '' ? 'Enter the value' : posterForm?.initial_label1 }
                                                {...formik.getFieldProps('initial_label1')}
                                                />
                                                {formik.touched.initial_label1 && formik.errors.initial_label1 && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.initial_label1}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {/* POSTER 2 */}
                                        {posterForm.initial_label_icon2 &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.initial_label2Required ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>{posterForm?.initial_label2 == '' ? 'No Name' : posterForm?.initial_label2 }</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder={posterForm?.initial_label2 == '' ? 'Enter the value' : posterForm?.initial_label2 }
                                                {...formik.getFieldProps('initial_label2')}
                                                />
                                                {formik.touched.initial_label2 && formik.errors.initial_label2 && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.initial_label2}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {/* POSTER 3 */}
                                        {posterForm.initial_label_icon3 &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.initial_label3Required ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>{posterForm?.initial_label3 == '' ? 'No Name' : posterForm?.initial_label3 }</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder={posterForm?.initial_label3 == '' ? 'Enter the value' : posterForm?.initial_label3 }
                                                {...formik.getFieldProps('initial_label3')}
                                                />
                                                {formik.touched.initial_label3 && formik.errors.initial_label3 && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.initial_label3}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {/* POSTER 4 */}
                                        {posterForm.initial_label_icon4 &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.initial_label4Required ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>{posterForm?.initial_label4 == '' ? 'No Name' : posterForm?.initial_label4 }</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder={posterForm?.initial_label4 == '' ? 'Enter the value' : posterForm?.initial_label4 }
                                                {...formik.getFieldProps('initial_label4')}
                                                />
                                                {formik.touched.initial_label4 && formik.errors.initial_label4 && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.initial_label4}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {/* POSTER 5 */}
                                        {posterForm.initial_label_icon5 &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.initial_label5Required ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>{posterForm?.initial_label5 == '' ? 'No Name' : posterForm?.initial_label5 }</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder={posterForm?.initial_label5 == '' ? 'Enter the value' : posterForm?.initial_label5 }
                                                {...formik.getFieldProps('initial_label5')}
                                                />
                                                {formik.touched.initial_label5 && formik.errors.initial_label5 && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.initial_label5}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.field_officer_code &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.field_officer_codeRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Field Officer Code</label>
                                            <div className='col-lg-8 fv-row'>
                                                <input type='text'
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter Field Officer Code..'
                                                {...formik.getFieldProps('officerCode')}
                                                />
                                                {formik.touched.officerCode && formik.errors.officerCode && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.officerCode}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}
                                        {posterForm.sloganField &&
                                        <div className='row mb-6'>
                                            <label className={posterForm.sloganFieldRequired ? 'col-lg-4 col-form-label fw-bold fs-6 required' : 'col-lg-4 col-form-label fw-bold fs-6'}>Slogan</label>
                                            <div className='col-lg-8 fv-row'>
                                                <textarea rows={3}
                                                className='form-control form-control-lg form-control-solid border'
                                                placeholder='Enter a slogan about us..'
                                                {...formik.getFieldProps('slogan')}
                                                />
                                                {formik.touched.slogan && formik.errors.slogan && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>
                                                            <span role='alert' className='text-danger'>{formik.errors.slogan}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>}                                    
                                        <div className='row mb-6'>
                                            <label className='col-lg-4 col-form-label fw-bold fs-6'></label>
                                            <div className='col-lg-8 fv-row required'>
                                                <input type='checkbox' className='form-check-input me-3' id='lfkhwiuhiwuehriwueyrwuefbhufsuaygi'
                                                onChange={(e) => {
                                                    formik.setFieldValue('accept', e.target.checked ? 1 : 0);
                                                }}
                                                />I Accept 
                                                {/* <a href={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_API_URL}/uploads/campaign/pdf/${poster.id}/${poster.terms_conditions}`} target='blank' className='cursor-pointer'>Terms & Conditions</a> */}
                                                <a href={process.env.REACT_APP_FRONT_URL+'terms_conditions?cid='+poster.id} target='blank' className='cursor-pointer'>Terms & Conditions</a>
                                                {/* <a href={process.env.REACT_APP_API_URL+'/uploads/campaign/pdf/'+poster.id+'/'+poster.terms_conditions} className='cursor-pointer'>Terms & Conditions</a> */}
                                                of this participation offer.
                                            </div>
                                        </div>
                                        <span className='d-flex justify-content-end'>
                                            <button type='button' className='btn btn-sm btn-secondary me-3' data-bs-dismiss='modal' onClick={() => {
                                                formik.resetForm();
                                            }}>Discard</button>
                                            {formik.values.accept != '0' && formik.values.accept && <button type='submit' className='btn btn-sm btn-primary'>Submit</button>}
                                        </span>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </> : 
            <div className='w-100 d-flex justify-content-center'>
                <span>
                    <img src={toAbsoluteUrl('/media/thank_you.png')} className='mw-500px' />
                    <h3 className='mt-5 text-center'>Transaction id: {trId}</h3>
                </span>
            </div>}
        </div>
        : 
        <div className='w-100 h-100vh'>
            <div className='d-flex justify-content-center flex-column align-items-center h-100'>
                <img src={toAbsoluteUrl('/media/timeOut.png')} />
                There is no campaign right now!
            </div> 
        </div>
        }

        <div className="footer footer-fixed py-4">

            <div className='d-sm-block d-none'>
                <div className="container-fluid d-flex align-items-center justify-content-center">
                    <div className="text-dark order-2 order-md-1">
                        <span className="text-muted fw-bold me-2">2023 ©</span>
                        <a href="#" className="text-gray-800 text-hover-primary">The Atom | <small className='text-gray-500'>Technical Partner</small> @ Vriksha Techno Solutions Pvt Ltd</a>
                    </div>
                </div>
            </div>

            <div className='d-sm-none d-block'>
                <div className="container-fluid d-flex flex-column align-items-center justify-content-center">
                    <div className="text-dark">
                        <span className="text-muted fw-bold me-2">2023 ©</span>
                        <a href="#" className="text-gray-800 text-hover-primary">The Atom </a>
                    </div>
                    <div  className="text-dark">
                        <a href="#" className="text-dark"><small className='text-gray-500'>Technical Partner</small> @ Vriksha Techno Solutions Pvt Ltd</a>
                    </div>
                </div>
            </div>

        </div>
        

    </>)
}

export default Campaigns;